import {Component} from '@angular/core';
import {IonicModule, PopoverController} from '@ionic/angular';
import {LanguageService} from '../../providers/language-service/language.service';
import {NgForOf} from '@angular/common';

/**
 * Komponente für die Sprachauswahl
 */
@Component({
  selector: 'mits-language-picker',
  templateUrl: './mits-language-picker.component.html',
  styleUrls: ['./mits-language-picker.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    NgForOf

  ],
  providers: [PopoverController]
})
export class MitsLanguagePicker {
  // Zeigt an, ob das Popover open ist
  isPopoverOpen = false;

  constructor(public readonly selectedLanguageService: LanguageService) {
  }

  selectLanguage(language: string): void {
    this.selectedLanguageService.switchToLanguage(language);
    this.isPopoverOpen = false;
  }
}
