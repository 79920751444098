import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule, ViewDidEnter} from '@ionic/angular';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MitsHeaderModule} from '../../../components/mits-header/mits-header.module';
import {
    MitsFormObjectSelectionComponent
} from '../../../components/_form-components/mits-form-object-selection/mits-form-object-selection.component';
import {CustomerService} from '../../../providers/model-services/customer.service';
import {CustomerModel} from '../../../models/customers/customer';
import {MitsFooterComponent} from '../../../components/mits-footer/mits-footer.component';
import {MitsFormCheckboxComponent} from '../../../components/_form-components/mits-form-checkbox/mits-form-checkbox.component';
import {MitsFormHeaderComponent} from '../../../components/_form-components/mits-form-header/mits-form-header.component';
import {MitsBoilerplatesModule} from '../../../components/mits-boilerplates/mits-boilerplates.module';
import {ChecklistService} from '../../../../packages/checklistManagement/providers/checklist.service';
import {IChecklist} from '../../../../packages/checklistManagement/models/checklist';
import {MachineService} from '../../../providers/model-services/machines/machine.service';
import {firstValueFrom} from 'rxjs';
import {CustomerContactModel} from '../../../models/customers/contact';
import {MitsDataGridComponent} from '../../../components/mits-data-grid/mits-data-grid.component';
import {MachineModel} from '../../../models/machine';
import {MitsDataGridColumnConfig} from '../../../components/mits-data-grid/model';
import {FormGroupType} from '../../../utils/forms/form.utils';
import {EmergencyOrderFormModel} from './models/emergency-order-form.model';
import {IndexDataFunction} from '../../../providers/page-services/index-data/index-data.interface';
import {ResponseModel} from '../../../models/response';
import {EmergencyOrderSubmitService} from './services/create-emergency-order.service';
import {FunctionSwitchesFromConfig, getFunctionSwitches} from '../../../utils/function-switches/function-switch.utils';
import {FunctionSwitchConfig} from './config/function-switch.config';
import {FormControlStatusDirective} from '../../../directives/form-control-status.directive';
import {CustomerLocationModel} from '../../../models/customers/location';
import {ObjectNameReponse} from '@vending/sync-engine-client/lib/interfaces/response';

/**
 * Seite für die Erstellung eines Notfallauftrags
 */
@Component({
    selector: 'app-emergency-order-old-new',
    templateUrl: './emergency-order-page.component.html',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        MitsHeaderModule,
        MitsFormObjectSelectionComponent,
        MitsFooterComponent,
        MitsFormCheckboxComponent,
        MitsFormHeaderComponent,
        MitsBoilerplatesModule,
        MitsDataGridComponent,
        FormControlStatusDirective,
    ],
    providers: [
        EmergencyOrderSubmitService,
    ],

})
export class EmergencyOrderPage implements ViewDidEnter {
    // Das Formular für den Notfallauftrag
    _FORM: FormGroup<FormGroupType<EmergencyOrderFormModel>>;
    // Funktionsschalter für die Seite
    _FS: FunctionSwitchesFromConfig<typeof FunctionSwitchConfig> = getFunctionSwitches(FunctionSwitchConfig);
    // Die Liste der Auswählbaren Ansprechpartner für den Auftrag (wird beim Kundenwechsel aktualisiert)
    selectableCustomerContacts: CustomerContactModel[] = [];
    // Der letzte index für die Lokale Suche der Kunden in der Datenbank
    lastCustomerSearchIndex: number = 0;
    // Wurde bei der Suche das letzte Ergebnis zurückgegeben ?
    isLastSearchResult: boolean = false;

    lastSearchTerm: string = '';
    // Die Liste der Auswählbaren Standorte für den Auftrag (wird beim Kundenwechsel aktualisiert)
    selectableCustomerLocations: (CustomerLocationModel & {typeDisplay: string})[] = [];
    // Die Titelfelder der Maschinenauswahl (wird auch für die Suche verwendet),
    machineSearchableTitleFields: string[] = ['inventory_number', 'name', 'serial_number'];
    // Die Subtitlefelder der Maschinenauswahl (wird auch für die Suche verwendet)
    machineSearchableSubtitleFields: string[] = ['stand'];
    // Konfiguration der Spalten für das MitsDataGrid, welches die ausgewählten Maschinen anzeigt
    selectedMachinesColumnsConfig: MitsDataGridColumnConfig<MachineModel>[] = [
        {
            attribute: 'id',
            label: $localize`ID`,
        },
        {
            attribute: 'inventory_number',
            label: $localize`AT-Nummer`,
        },
        {
            attribute: 'serial_number',
            label: $localize`Seriennummer`,
        },
        {
            attribute: 'name',
            label: $localize`Name`,
        },
        {
            attribute: 'open_damages',
            label: $localize`Offene Schäden`,
        },
    ];
    // Zeigt an, ob die Maschinenauswahl zurückgesetzt werden soll (TODO: Bessere Lösung finden, als mit einem Counter zu arbeiten)
    reinitMachineSelection: number = 0;

    //////////////////////////// LIFECYCLE ////////////////////////////

    constructor(
        // OFFLINE DATA SERVICES
        private readonly customerService: CustomerService,
        private readonly checklistService: ChecklistService,
        private readonly machineService: MachineService,
        // PAGE SERVICES
        private readonly emergencyOrderSubmitService: EmergencyOrderSubmitService,
    ) {
    }

    /**
     * Wird aufgerufen, wenn die Seite betreten wird
     * - Initialisiert das Formular für den Notfallauftrag
     */
    async ionViewDidEnter(): Promise<void> {
        this._FORM = this.initForm();
    }

    //////////////////////////// NUTZERINTERAKTION ////////////////////////////
    /**
     * Wird aufgerufen, wenn sich die Auswahl des Kunden ändert
     * @param $customerSelection - der ausgewählte Kunde
     */
    customerSelectionChanged($customerSelection: CustomerModel | CustomerModel[]): void {
        this.resetOrderFormOnCustomerChange();
        if (!$customerSelection || Array.isArray($customerSelection)) return;
        this.selectableCustomerContacts = $customerSelection.contacts ?? [];
        this.selectableCustomerLocations = $customerSelection.locations.map(location => {
            return this.mapCustomerLocationTypeToDisplay(location);
        }) ?? [];
    }

    /**
     * Wird aufgerufen, wenn eine (oder mehrere) Maschinen ausgewählt wurden
     * @param $machineSelection - die ausgewählte Maschine(n)
     */
    machineSelectionChanged($machineSelection: MachineModel[] | MachineModel) {
        if (!$machineSelection) return;
        const machines: MachineModel[] = Array.isArray($machineSelection) ? $machineSelection : [$machineSelection];
        this._FORM.controls.machines.setValue([]);
        machines.forEach((machine: MachineModel) => {
            this._FORM.value.machines.push(machine);
        });
    }

    /**
     * Speichert den Auftrag
     */
    async save(): Promise<void> {
        await this.emergencyOrderSubmitService.submitEmergencyOrder(this._FORM.getRawValue());
    }

    //////////////////////////// DATENFUNKTIONEN FÜR DIE SELECTIONS ////////////////////////////

    /**
     * Lädt die Maschinendaten für die Auswahlliste
     * - Filtert zusätzlich nach der Kunden-ID und der Maschinenstandort-ID
     * @param page - die Seite, für die die Daten abgerufen werden sollen
     * @param search - der Suchbegriff, nach dem gefiltert werden soll
     * @returns Promise mit den Daten für die Auswahlliste, oder einem leeren Array, falls ein Fehler auftritt
     */
    readonly machinesDataFunction: IndexDataFunction<MachineModel>
        = async (page?: number, search?: string)
        : Promise<ResponseModel<MachineModel>> => {
        try {
            // Wenn keine Kunden-ID oder Standort-ID ausgewählt wurde, werden keine Maschinen geladen
            if(!this._FORM.value.customer.id || !this._FORM.value.location.id) {
                return {data: [], pagy: {page: 1, last: 1}};
            }
            // Maschinen des Kunden laden
            let machines: MachineModel[] = await this.machineService.allByCustomerId(
                this._FORM.controls.customer.value.id.toString()
            );
            // Maschinen entsprechend des ausgewählten Standort-Typs filtern
            if(
                this._FORM.controls.location.value.type === 'Customers::LocationMachine' ||
                this._FORM.controls.location.value.type === 'Customers::Location'
            ) {
                machines = machines.filter(machine =>
                    machine.customer_location.id === this._FORM.controls.location.value.id);
            } else if (
                this._FORM.controls.location.value.type === 'Customers::LocationBufferstock'
            ) {
                machines = machines.filter(machine =>
                    machine.customer_location_bufferstock.id === this._FORM.controls.location.value.id
                );
            }
            // Maschinen unter Berücksichtigung der Titelfelder und des Suchbegriffs filtern
            const searchableFields = [...this.machineSearchableTitleFields, ...this.machineSearchableSubtitleFields]
            if(search && search.trim().length > 0) {
                machines = machines.filter((machine: MachineModel): boolean => {
                    return searchableFields.some((field: string): any => {
                        return machine[field] && machine[field].toLowerCase().includes(search.toLowerCase());
                    });
                });
            }
            // Maschinen zurückgeben als ResponseModel (Seite 1 von 1, da keine Pagination)
            return {
                data: machines,
                pagy: {page: 1, last: 1},
            }
        } catch (error) {
            console.error('[EmergencyOrderPage][machinesDataFunction()]: Fehler beim Laden der Maschinen', error);
            return {
                data: [],
                pagy: {page: 1, last: 1,},
            };
        }
    };

    /**
     * Lädt die Kundendaten für die Auswahlliste
     * @param requestedPage - die Seite, für die die Daten abgerufen werden sollen
     * @param search - der Suchbegriff, nach dem gefiltert werden soll
     * @returns Promise mit den Daten für die Auswahlliste, oder einem leeren Array, falls ein Fehler auftritt
     */
    readonly customersDataFunction: IndexDataFunction<CustomerModel>
        = async (requestedPage?: number, search?: string)
        : Promise<ResponseModel<CustomerModel>> => {
        try {
            // Wenn der Suchbegriff sich geändert hat, wird der Startindex für die nächste Suche zurückgesetzt
            if(this.lastSearchTerm !== search) this.lastCustomerSearchIndex = 0;
            // Letzten Suchbegriff speichern
            this.lastSearchTerm = search;
            // Kundendaten laden
            const response: ObjectNameReponse<CustomerModel> = await firstValueFrom(
                this.customerService.localWhereByObjectName(this.lastCustomerSearchIndex, search)
            )
            // Letzten Suchindex speichern
            this.lastCustomerSearchIndex = response.index;
            return {
                data: response.data,
                pagy: {
                    // Tatsächliche Seite ist angeforderte Seite
                    page: requestedPage,
                    // Wenn letzte Seite erreicht, ist die letzte Seite die angeforderte Seite
                    last: response.last ? requestedPage : requestedPage + 1,
                }
            }
        } catch (error) {
            console.error('[EmergencyOrderPage][customersDataFunction()]: Fehler beim Laden der Kunden', error);
            return {
                data: [],
                pagy: {page: 1, last: 1}
            };
        }
    };

    /**
     * Lädt die Checklisten für die Auswahlliste
     * @returns Promise mit den Daten für die Auswahlliste, oder einem leeren Array, falls ein Fehler auftritt
     */
    readonly checklistsDataFunction: IndexDataFunction<IChecklist>
        = async ()
        : Promise<ResponseModel<IChecklist>> => {
        try {
            const checklists: IChecklist[] = await this.checklistService.resourceLocal('Order') ?? [];
            return {
                data: checklists,
                pagy: {page: 1, last: 1}
            };
        } catch (error) {
            console.error('[EmergencyOrderPage][checklistsDataFunction]: Fehler beim Laden der Checklisten', error);
            return {
                data: [],
                pagy: {page: 1, last: 1,},
            };
        }
    }

    //////////////////////////// HILFSMETHODEN ////////////////////////////

    /**
     * Initialisiert das Formular für den Notfallauftrag
     * @returns FormGroup für den Notfallauftrag
     * @private
     */
    private initForm(): FormGroup<FormGroupType<EmergencyOrderFormModel>> {
        return new FormGroup<FormGroupType<EmergencyOrderFormModel>>({
            customer: new FormControl(null, Validators.required),
            location: new FormControl(null, Validators.required),
            customer_contact_id: new FormControl(null),
            filler_order: new FormControl(false, Validators.required),
            description: new FormControl('', this._FS.decriptionNeededOnOrder ? Validators.required : null),
            checklists: new FormControl(null),
            machines: new FormControl([]),
        });
    }

    /**
     * Setzt bestimmte Werte im Formular zurück, wenn sich der Kunde ändert
     * - Setzt die Auswahlmöglichkeiten für Ansprechpartner zurück
     * - Setzt den ausgewählten Ansprechpartner zurück
     * - Setzt die ausgewählten Maschinen zurück
     * - Triggert das Neuladen der Maschinen, da sich die Kunden-ID geändert hat
     * @private
     */
    private resetOrderFormOnCustomerChange() {
        this.selectableCustomerContacts = [];
        this._FORM.controls.customer_contact_id.setValue(null);
        this._FORM.controls.machines.setValue([]);
        this.reinitMachineSelection++; // TODO: Bessere Lösung finden, als mit einem Counter zu arbeiten
    }

    /**
     * Setzt bestimmte Werte im Formular zurück, wenn sich der Standort ändert
     * - Setzt die ausgewählten Maschinen zurück
     * - Triggert das Neuladen der Maschinen, da sich die Standort-ID geändert hat
     * @private
     */
    resetOrderFormOnLocationChange() {
        this._FORM.controls.machines.setValue([]);
        this.reinitMachineSelection++; // TODO: Bessere Lösung finden, als mit einem Counter zu arbeiten
    }


    /**
     * Erweitert den Standort um ein lesbares Typ-Attribut für die Anzeige
     * @param location - der Standort
     * @returns der Standort mit dem lesbaren Typ
     * @private
     */
    private mapCustomerLocationTypeToDisplay(location: CustomerLocationModel): (CustomerLocationModel & {typeDisplay: string}) {
        let typeDisplay = '';
        switch(location.type) {
            case 'Customers::Location':
                typeDisplay = 'Kundenstandort';
                break;
            case 'Customers::LocationMachine':
                typeDisplay = 'Maschinenstandort';
                break;
            case 'Customers::LocationBufferstock':
                typeDisplay = 'Bufferstockstandort';
                break;
        }
        return {
            ...location,
            typeDisplay,
        }
    }
}