export type Right = keyof typeof RIGHTS;
export type RouteRight = Record<string, string[]>;

// All rights for all features as small as possible
export const RIGHTS = {
  CUSTOMER_LIST: 'Kunden auflisten',
  CUSTOMER_EDIT: 'Kunden verwalten',
  CUSTOMER_SHOW: 'Kunden ansehen',
  CUSTOMER_DELETE: 'Kunden löschen',
  MANDATOR_LIST: 'Mandator auflisten',
  MANDATOR_SHOW: 'Mandator ansehen',
  MANDATOR_EDIT: 'Mandator verwalten',
  ARTICLE_LIST: 'Artikel auflisten',
  ARTICLE_SHOW: 'Artikel ansehen',
  ARTICLE_EDIT: 'Artikel verwalten',
  ARTICLE_DELETE: 'Artikel löschen',
  USER_LIST: 'User auflisten',
  USER_SHOW: 'User ansehen',
  USER_EDIT: 'User verwalten',
  USER_DELETE: 'User löschen',
  FUNCTION_LIST: 'FunktionSwitch auflisten',
  FUNCTION_EDIT: 'FunktionSwitch verwalten',
  PRIORITY_LIST: 'Prioritäten auflisten',
  PRIORITY_SHOW: 'Prioritäten ansehen',
  PRIORITY_EDIT: 'Prioritäten verwalten',
  PRIORITY_DELETE: 'Prioritäten löschen',
  STATE_LIST: 'Status auflisten',
  STATE_SHOW: 'Status ansehen',
  STATE_EDIT: 'Status verwalten',
  STATE_CREATE: 'Status erstellen',
  STATE_DELETE: 'Status löschen',
  BOILERPLATE_LIST: 'Textbausteine auflisten',
  BOILERPLATE_SHOW: 'Textbausteine ansehen',
  BOILERPLATE_EDIT: 'Textbausteine verwalten',
  BOILERPLATE_DELETE: 'Textbausteine löschen',
  ADDRESS_LIST: 'Adressen auflisten',
  ADDRESS_SHOW: 'Adressen ansehen',
  ADDRESS_EDIT: 'Adressen verwalten',
  ADDRESS_DELETE: 'Adressen löschen',
  DAMAGE_REPORT_LIST: 'Schadensberichte auflisten',
  DAMAGE_REPORT_SHOW: 'Schadensberichte ansehen',
  DAMAGE_REPORT_EDIT: 'Schadensberichte verwalten',
  DAMAGE_REPORT_DELETE: 'Schadensberichte löschen',
  DAMAGE_CATEGORY_LIST: 'Schadenskategorie auflisten',
  DAMAGE_CATEGORY_SHOW: 'Schadenskategorie ansehen',
  DAMAGE_CATEGORY_EDIT: 'Schadenskategorie verwalten',
  DAMAGE_CATEGORY_DELETE: 'Schadenskategorie löschen',
  ORDER_LIST: 'Service-Aufträge auflisten',
  ORDER_SHOW: 'Service-Aufträge ansehen',
  ORDER_EDIT: 'Service-Aufträge verwalten',
  ORDER_DELETE: 'Service-Aufträge löschen',
  MACHINE_LIST: 'Maschinen auflisten',
  MACHINE_SHOW: 'Maschinen ansehen',
  MACHINE_EDIT: 'Maschinen verwalten',
  MACHINE_EDIT_SERIAL_NUMBER: 'Maschine: Seriennummer editieren',
  MACHINE_DELETE: 'Maschinen löschen',
  MACHINE_TYPE_LIST: 'Maschinentyp auflisten',
  MACHINE_TYPE_SHOW: 'Maschinentyp ansehen',
  MACHINE_TYPE_EDIT: 'Maschinentyp verwalten',
  MACHINE_TYPE_DELETE: 'Maschinentyp löschen',
  MACHINE_STATE_LIST: 'Maschinenstatus auflisten',
  MACHINE_STATE_SHOW: 'Maschinenstatus ansehen',
  MACHINE_STATE_EDIT: 'Maschinenstatus verwalten',
  MACHINE_STATE_DELETE: 'Maschinenstatus löschen',
  MACHINE_GROUP_LIST: 'Maschinengruppen auflisten',
  MACHINE_GROUP_SHOW: 'Maschinengruppen ansehen',
  MACHINE_GROUP_EDIT: 'Maschinengruppen verwalten',
  MACHINE_GROUP_DELETE: 'Maschinengruppen löschen',
  DISTRIBUTOR_LIST: 'Lieferanten auflisten',
  DISTRIBUTOR_SHOW: 'Lieferanten ansehen',
  DISTRIBUTOR_EDIT: 'Lieferanten verwalten',
  DISTRIBUTOR_DELETE: 'Lieferanten löschen',
  CLIENT_DEVICE_LIST: 'Mobilgeräte auflisten',
  CLIENT_DEVICE_SHOW: 'Mobilgeräte ansehen',
  CLIENT_DEVICE_EDIT: 'Mobilgeräte verwalten',
  CLIENT_DEVICE_DELETE: 'Mobilgeräte löschen',
  DEVICE_POSITIONS_LIST: 'Mobilgerätepositionen auflisten',
  DEVICE_POSITIONS_SHOW: 'Mobilgerätepositionen ansehen',
  DEVICE_POSITIONS_EDIT: 'Mobilgerätepositionen verwalten',
  DEVICE_POSITIONS_DELETE: 'Mobilgerätepositionen löschen',
  BUSINESS_LIST: 'Betriebsstätten auflisten',
  BUSINESS_SHOW: 'Betriebsstätten ansehen',
  BUSINESS_EDIT: 'Betriebsstätten verwalten',
  BUSINESS_DELETE: 'Betriebsstätten löschen',
  UNIT_LIST: 'Einheiten auflisten',
  UNIT_SHOW: 'Einheiten ansehen',
  UNIT_EDIT: 'Einheiten verwalten',
  UNIT_DELETE: 'Einheiten löschen',
  ARTICLE_GROUP_LIST: 'Artikelgruppen auflisten',
  ARTICLE_GROUP_SHOW: 'Artikelgruppen ansehen',
  ARTICLE_GROUP_EDIT: 'Artikelgruppen verwalten',
  ARTICLE_GROUP_DELETE: 'Artikelgruppen löschen',
  CLIENT_SETTINGS_SHOW: 'Client-Einstellungen ansehen',
  CLIENT_SETTINGS_EDIT: 'Client-Einstellungen verwalten',
  TELEMETRY_DELIVERY: 'Telemetrie verwalten',
  TIMELOG_LIST: 'Zeiterfassung auflisten',
  TIMELOG_SHOW: 'Zeiterfassung ansehen',
  TIMELOG_EDIT: 'Zeiterfassung verwalten',
  TIMELOG_DELETE: 'Zeiterfassung löschen',
  TOUR_LIST: 'Touren auflisten',
  TOUR_SHOW: 'Touren ansehen',
  TOUR_EDIT: 'Touren verwalten',
  TOUR_DELETE: 'Touren löschen',
  DASHBOARD_LIST: 'Dashboard ansehen',
  TIMELOG_CORRECTION_LIST: 'Korrekturanfragen auflisten',
  TIMELOG_CORRECTION_SHOW: 'Korrekturanfragen ansehen',
  TIMELOG_CORRECTION_EDIT: 'Korrekturanfragen verwalten',
  WAREHOUSE_ORDER_LIST: 'Lagerbestellungen auflisten',
  WAREHOUSE_ORDER_CREATE_INITIAL: 'Lager: Erstbestellung auf Basket erstellen',
  CHECKLIST_LIST: 'Checklisten auflisten',
  CHECKLIST_SHOW: 'Checklisten ansehen',
  CHECKLIST_EDIT: 'Checklisten verwalten',
  CHECKLIST_DELETE: 'Checklisten löschen',
  TEMPLATE_LIST: 'Templates auflisten',
  TEMPLATE_SHOW: 'Templates ansehen',
  TEMPLATE_EDIT: 'Templates verwalten',
  TEMPLATE_DELETE: 'Templates löschen',
  FAILED_REQUEST_READ: 'Fehlgeschlagene Requests ansehen',
  FAILED_REQUEST_DELETE: 'Fehlgeschlagene Requests löschen',
};

export const ROUTE_RIGHTS: RouteRight = {
  statuses: [RIGHTS.STATE_LIST],
  boilerplates: [RIGHTS.BOILERPLATE_LIST],
  priorities: [RIGHTS.PRIORITY_LIST],
  function_switches: [RIGHTS.FUNCTION_LIST, RIGHTS.FUNCTION_EDIT],
  client_devices: [RIGHTS.CLIENT_DEVICE_LIST],
  damage_categories: [RIGHTS.DAMAGE_CATEGORY_LIST],
  users: [RIGHTS.USER_LIST],
  mandators: [RIGHTS.MANDATOR_LIST],
  'settings/cars': [RIGHTS.MANDATOR_LIST],
  customers: [RIGHTS.CUSTOMER_LIST],
  distributors: [RIGHTS.DISTRIBUTOR_LIST],
  articles: [RIGHTS.ARTICLE_LIST],
  article_units: [RIGHTS.UNIT_LIST],
  article_groups: [RIGHTS.ARTICLE_GROUP_LIST],
  machines: [RIGHTS.MACHINE_LIST],
  machine_types: [RIGHTS.MACHINE_TYPE_LIST],
  machine_groups: [RIGHTS.MACHINE_GROUP_LIST],
  machine_states: [RIGHTS.MACHINE_STATE_LIST],
  settings: ['Einstellungen ansehen'],
  dashboard: ['Dashboard ansehen', RIGHTS.ORDER_LIST],
  service_contracts: ['Backend Servicevaufträge auflisten'],
  checklists: [RIGHTS.CHECKLIST_LIST],
  templates: [RIGHTS.TEMPLATE_LIST],
  damages_reports: [RIGHTS.DAMAGE_REPORT_LIST],
  'damages_reports/create': [RIGHTS.DAMAGE_REPORT_EDIT],
  'damages_reports/update': [RIGHTS.DAMAGE_REPORT_EDIT],
  'damageManagement/damage_reports': ['Backend Schadensberichte auflisten'],
  damage_reports: ['Backend Schadensberichte auflisten'],
  'dashboard-worker': ['Uploadqueue auflisten'],
  'tour-management': [RIGHTS.TOUR_LIST, RIGHTS.TOUR_EDIT],
  'dashboard/orders': [RIGHTS.ORDER_LIST],
  'system/timelog_corrections': [RIGHTS.TIMELOG_CORRECTION_LIST],
  'warehouse/orders': [RIGHTS.WAREHOUSE_ORDER_LIST],
  timelogs: [RIGHTS.TIMELOG_LIST],
  'emergency-order-new': [RIGHTS.ORDER_EDIT],
  'orders/active': [RIGHTS.ORDER_LIST],
  'orders/overview': [RIGHTS.ORDER_LIST],
  'dashboard-inside': [RIGHTS.ORDER_LIST, RIGHTS.DASHBOARD_LIST],
  'failed-request-monitoring': [RIGHTS.FAILED_REQUEST_READ],
  // Verschachtelte RouteRights greifen durch die Ermittlung der volständigen Route im AccessGuard (#715)
  'settings/statuses': [RIGHTS.STATE_LIST],
  'settings/statuses/details/:id': [RIGHTS.STATE_SHOW, RIGHTS.STATE_EDIT, RIGHTS.STATE_DELETE, RIGHTS.STATE_CREATE],
  'settings/priorities': [RIGHTS.PRIORITY_LIST],
  'settings/priorities/details/:id': [RIGHTS.PRIORITY_SHOW, RIGHTS.PRIORITY_EDIT, RIGHTS.PRIORITY_DELETE],
};
