/**
 * Breakpoints des MitsDataGrids
 */
export type MitsDataGridBreakpoints = 'xs' |'sm' | 'md' | 'lg' | 'xl' | 'xxl';

/**
 * Zuordung der Breakpoints des MitsDataGrids zur Bildschirmbreite in Pixeln
 */
export const MITS_DATA_GRID_BREAKPOINT_MAPPINGS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1450,
};
