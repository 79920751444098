import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastService } from 'src/app/providers/component-helpers/toast.service';
import { MitsScannerModalPage } from './mits-scanner-modal/mits-scanner-modal.page';

export interface ScannerData {
  decodedResult: {
    decodedText: string;
  };
}

@Component({
  selector: 'mits-scanner-button',
  templateUrl: './mits-scanner-button.component.html',
  styleUrls: ['./mits-scanner-button.component.scss'],
})
export class MitsScannerButtonComponent {
  /** Should a text be shown to the button */
  @Input() text?: string;
  /** Should the button be disabled */
  @Input() disabled?: boolean;
  /** Should the button get as wide as it has space? */
  @Input() fullSize?: boolean;
  /** Should the button get as high as it has space? */
  @Input() fullHeight?: boolean;
  /** Set a size for the button */
  @Input() size?: number = 42;
  /** Set a width for the button if other than size */
  @Input() width?: number;
  /** The scanned data passed by scanner-modal */
  @Output() scannerData: EventEmitter<ScannerData> =
    new EventEmitter<ScannerData>();

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly toastService: ToastService
  ) {}

  clickScannerButton() {
    this.presentModal();
  }

  /**
   * Function displays the modal of a checklist group and adds a new group to an array of groups.
   * @param create if create == true a new object of a group will be created.
   */
  private async presentModal() {
    const modal = await this.modalCtrl.create({
      component: MitsScannerModalPage,
      cssClass: 'scanner-modal',
      componentProps: {},
      backdropDismiss: false,
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (modal) await modal.dismiss(null);
    if (data?.decodedResult) {
      this.toastService.custom($localize`Scan Erfolgreich!`, 'primary', 1000, [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ]);
      this.scannerData.emit(data);
    }
  }
}
