import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {
  transform(list: any[], filterText: string, enabled: boolean = true): any {
    if (!enabled) {
      return list;
    }
    return list
      ? list.filter((item) => {
          if (!item) return false;
          let fullItemString = '';
          Object.keys(item).forEach((key) => {
            const val = item[key];
            fullItemString += val ? String(val) + ' ' : '';
          });
          return fullItemString.search(new RegExp(filterText, 'i')) > -1;
        })
      : [];
  }
}
