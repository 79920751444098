<ion-footer *ngIf="currentPage && lastPage">
  <ion-buttons>
    <ion-button expand="block"
                (click)="clickFirstPageButton()"
                [disabled]="isFirstPage">
      <mits-icon name="push-left"></mits-icon>
    </ion-button>
    <ion-button expand="block"
                (click)="clickPrevPageButton()"
                [disabled]="isFirstPage">
      <mits-icon name="arrow-left"></mits-icon>
    </ion-button>
    <ion-col size="3">
      <ion-input class="ion-text-center" type="number"
                 #pageInput [value]="currentPage"
                 (input)="inputSpecificPage()"
                 [disabled]="isOnlyOnePage">
      </ion-input>
    </ion-col>
    <ion-button expand="block"
                (click)="clickNextPageButton()"
                [disabled]="isLastPage">
      <mits-icon name="arrow-right"></mits-icon>
    </ion-button>
    <ion-button expand="block"
                (click)="clickLastPageButton()"
                [disabled]="isLastPage">
      <mits-icon name="push-right"></mits-icon>
    </ion-button>
  </ion-buttons>
</ion-footer>
