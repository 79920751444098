import {
    SA_DESCRIPTION_NEEDED, SA_ENABLE_CHECKLISTS,
    USER_CAN_ADD_ONLY_ONE_MACHINE
} from '../../../../../assets/constants/functionSwitch.constants';

/**
 * Konfiguration der Funktionsschalter für die EmergencyOrderPage
 */
export const FunctionSwitchConfig = {
    // Checklisten am Auftrag aktivieren
    checklistOnOrder: SA_ENABLE_CHECKLISTS,
    // Auftragsbeschreibung / Tätigkeit Pflichfeld
    decriptionNeededOnOrder: SA_DESCRIPTION_NEEDED,
    // Maximal eine Maschine pro Auftrag
    canOnlyAddSingleMachine: USER_CAN_ADD_ONLY_ONE_MACHINE
};