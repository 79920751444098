import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MitsIconModule } from 'src/app/components/mits-icon/mits-icon.module';
import { SearchFilterPipe } from '../mits-table/pipes/search-filter';
import { MitsObjectSelectHelper } from './helpers/mits-object-select-helper';
import { MitsObjectSelectModalPageModule } from './mits-object-select-modal/mits-object-select-modal.module';
import { MitsObjectSelectComponent } from './mits-object-select.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    MitsIconModule,
    MitsObjectSelectModalPageModule,
  ],
  declarations: [MitsObjectSelectComponent],
  entryComponents: [MitsObjectSelectComponent],
  exports: [MitsObjectSelectComponent],
  providers: [MitsObjectSelectHelper, SearchFilterPipe],
})
export class MitsObjectSelectModule {}
