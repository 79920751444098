import { MitsIconModule } from '../mits-icon/mits-icon.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { MitsScannerButtonComponent } from './mits-scanner-button.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, MitsIconModule],
  declarations: [MitsScannerButtonComponent],
  entryComponents: [MitsScannerButtonComponent],
  exports: [MitsScannerButtonComponent],
  providers: [],
})
export class MitsScannerButtonModule { }
