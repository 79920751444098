<ion-item *ngIf="!hideInputField" (click)="openModal()">
    <ion-label position="floating">{{ label }}</ion-label>
    <ion-input [placeholder]="placeholder"
               readonly
               [value]="value | ObjectSelectionDisplayPipe: titleFields">
    </ion-input>

    <ion-badge *ngIf="value && badgeFields" slot="start"
               color="primary">
        {{ value | ObjectSelectionDisplayPipe: badgeFields }}
    </ion-badge>
</ion-item>
<ion-modal *ngIf="isModalOpen" [isOpen]="isModalOpen" (didDismiss)="closeModal()">
    <ng-template>
        <mits-loading-spinner *ngIf="(indexDataService.isLoading$ | async) && !dataInitialized"
                              text="Daten werden geladen..."></mits-loading-spinner>
        <mits-header [title]="title" [modal]="true" (closed)="closeModal(false)"></mits-header>
        <ion-content>
            <div class="header">
                <ion-item *ngIf="!multiple && value">
                    <ion-badge *ngIf="badgeFields" slot="start"
                               color="primary">
                        {{ value | ObjectSelectionDisplayPipe: badgeFields }}
                    </ion-badge>
                    <div class="label">
                        <ion-label class="title ion-text-wrap">{{ value | ObjectSelectionDisplayPipe: titleFields }}
                        </ion-label>
                        <ion-label
                                class="subtitle ion-text-wrap">{{ value | ObjectSelectionDisplayPipe: subtitleFields }}
                        </ion-label>
                    </div>
                    <ion-icon name="close-circle-outline" color=danger
                              (click)="handleSingleSelection(undefined)"></ion-icon>
                </ion-item>
                <div class="searchbar">
                    <div *ngIf="enableScanner" class="scannerButtonContainer">
                        <mits-scanner-button [size]="28" [width]="42"
                                             (scannerData)="scannedData($event)"></mits-scanner-button>
                    </div>

                    <ion-searchbar *ngIf="!disableSearch" mode="ios" placeholder="Suchen..."
                                   [formControl]="indexDataService.searchField"></ion-searchbar>
                    <ion-button [color]="showOnlySelected? 'primary' : 'medium'"
                                style="--box-shadow:none; --border-radius:10px; margin-right:12px;" *ngIf="multiple"
                                (click)="toggleShowOnlySelected()">
                        <ion-icon [color]="showOnlySelected? 'light' : 'tertiary'" slot="icon-only" name="checkbox-outline"></ion-icon>
                    </ion-button>

                </div>
            </div>
            <ion-list class="itemsList" *ngIf="indexDataService.data$ | async">
                <ion-item lines="none"
                          *ngIf="!(indexDataService.isLoading$ | async) && dataInitialized && (indexDataService.data$ | async).length === 0">
                    <ion-text color="primary">
                        <p>Keine Daten
                            {{ indexDataService.searchField?.value?.trim()?.length > 0 ? 'für "' + indexDataService.searchField?.value + '"' : '' }}
                            gefunden...</p>
                    </ion-text>
                </ion-item>
                <ion-item lines="none" *ngIf="multiple && showOnlySelected && noSelections">
                    <ion-text color="primary">
                        <p>Keine Objekte ausgewählt...</p>
                    </ion-text>
                </ion-item>
                <ng-container *ngFor="let object of indexDataService.data$ | async; trackBy: trackById">
                    <ion-item
                            *ngIf="(!multiple && !isSelected(object)) || multiple && (!showOnlySelected || (isSelected(object)))"
                            lines="full"
                            (click)="!multiple ? handleSingleSelection(object) : null">
                        <ion-checkbox *ngIf="multiple" slot="start" [checked]="isSelected(object)"
                                      (ionChange)="onCheckboxChange($event, object)"></ion-checkbox>
                        <ion-badge *ngIf="badgeFields" slot="start" color="primary">
                            {{ object | ObjectSelectionDisplayPipe: badgeFields }}
                        </ion-badge>
                        <div class="label">
                            <ion-label
                                    class="title ion-text-wrap">{{ object | ObjectSelectionDisplayPipe: titleFields }}
                            </ion-label>
                            <ion-label
                                    class="subtitle ion-text-wrap">{{ object | ObjectSelectionDisplayPipe: subtitleFields }}
                            </ion-label>
                        </div>
                    </ion-item>
                </ng-container>
                <ion-infinite-scroll
                        *ngIf="!showOnlySelected && loadingType === 'infinite-scroll' && (indexDataService.hasMorePages$ | async)"
                        (ionInfinite)="onIonInfinite($event)">
                    <ion-infinite-scroll-content></ion-infinite-scroll-content>
                </ion-infinite-scroll>
            </ion-list>
        </ion-content>
        <ion-footer>
            <ion-progress-bar *ngIf="indexDataService.isLoading$ | async" type="indeterminate"></ion-progress-bar>
            <mits-pagination *ngIf="loadingType=== 'pagination'"
                             [currentPage]="indexDataService.currentPage.value"
                             [lastPage]="indexDataService.lastPage$ | async"
                             (pageChanged)="pageChanged($event)">
            </mits-pagination>
            <ion-row class="ion-no-padding">
                <ion-col class="ion-no-padding">
                    <ion-button class="ion-no-padding ion-no-margin" color=danger expand=full (click)="closeModal(false)">
                        Abbrechen
                    </ion-button>
                </ion-col>
                <ion-col class="ion-no-padding">
                    <ion-button class="ion-no-padding ion-no-margin" color=primary expand=full (click)="closeModal(true)">
                        Übernehmen
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-footer>
    </ng-template>
</ion-modal>
