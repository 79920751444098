import { Injectable } from '@angular/core';
import {DataService} from '../../../app/providers/data.service';
import {CarInventoryModel} from '../../../app/pages/backend/inventories/models/car/car.inventory.model';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

/**
 * Modell für die Backend-Seitige Initialisierung einer Inventur
 */
export type CarInventoryInitRequestModel = Pick<CarInventoryModel, 'car_id' | 'user_id' | 'created_time'>

/**
 * DataService für die Anzeige und erstellung der Inventuren von Fahrzeugen
 * Für die Buchungen der Fahrzeuginventur wird ein anderer Endpunkt benötigt
 * @see CarInventoryMovementsService
 */
@Injectable({
  providedIn: 'root',
})
export class CarInventoryService extends DataService<CarInventoryModel> {
  endpoint = 'car_management/car_inventories/';


  /**
   * Erstellt eine neue Inventur für ein Fahrzeug
   * @param inventory - die Daten für die Backend-Seitige Initialisierung der Inventur
   * @returns Die ackend-Seitig initialisierte Inventur
   */
  newInventory(inventory: CarInventoryInitRequestModel): Observable<CarInventoryModel> {
    return this.http
        .post<any>(this.endpointWithUrl, inventory)
        .pipe(catchError(this.errorService.convert));
  }
}
