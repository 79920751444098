<mits-header [backButton]="false" [modal]="true" [allowClose]="false" title="Einrichtung der App"></mits-header>

<ion-content>
  <ion-list inset>
    <ion-item>
      <ion-icon slot="start" size="large" name="information-circle-outline"></ion-icon>
      <ion-text color="tertiary" class="smallDescription" i18n>
        Bitte warten Sie, bis die Einrichtung abgeschlossen ist. Dieser Vorgang kann einige Zeit in Anspruch nehmen.
      </ion-text>
    </ion-item>
  </ion-list>

  <ion-list inset lines="none" class="ion-no-padding">
    <ng-container *ngFor="let task of tasks">
      <ion-item>
        <div class="ion-padding-vertical">
          <ion-label color="tertiary" class="ion-text-wrap">
            <strong>{{task.title}}</strong>
          </ion-label>
          <ion-text color="tertiary" class="smallDescription" *ngIf="task.description">{{task.description}}</ion-text>
        </div>
        <ion-text color="primary" slot="end" class="smallDescription ion-no-margin" *ngIf="task.percentage < 100">
          <div *ngIf="task.percentage > 0">{{task.percentage | number:"1.0-0"}}%</div>
          <ion-text color="tertiary" *ngIf="task.timeRemaining > 0 && showRemaining(task)">
            {{task.timeRemaining | time}}
          </ion-text>
        </ion-text>
        <ion-icon slot="end" size="large" *ngIf="task.status === 'pending'" name="pause-circle-outline"
          color="tertiary"></ion-icon>
        <ion-icon slot="end" size="large" *ngIf="task.status === 'inProgress'" name="refresh-circle-outline"
          class="animateRotate" color="primary"></ion-icon>
        <ion-icon slot="end" size="large" *ngIf="task.status === 'completed'" name="checkmark-done-circle-outline"
          color="success"></ion-icon>
      </ion-item>
      <ion-progress-bar *ngIf="!task.proggressEvent || task.percentage >= 0"
        [type]="task.proggressEvent || task.status != 'inProgress'  ? 'determinate' : 'indeterminate'"
        [color]="stateColor[task.status]" [value]="task.percentage / 100 ?? 0"></ion-progress-bar>
    </ng-container>
  </ion-list>
</ion-content>