import {CommonModule} from '@angular/common';
import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {MitsFormCheckboxControlValueAccessorDirective} from './directives/mits-form-checkbox.control-value-accessor.directive';


/**
 * Formularfeld für eine Checkbox
 */
@Component({
  selector: 'mits-form-checkbox',
  templateUrl: './mits-form-checkbox.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
  ],
  hostDirectives: [MitsFormCheckboxControlValueAccessorDirective],
})
export class MitsFormCheckboxComponent {
  // Label des Checkbox-Feldes
  @Input() label = 'Auswahl';
  // Boolean, der angibt, ob das Feld deaktiviert ist
  @Input() disabled = false;
  // Wert der Checkbox
  @Input() checked?: boolean;
  // EventEmitter für Auswahländerungen
  @Output() checkedChage: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Boolean, der angibt, ob das Feld nur lesbar ist
  @Input() readonly = false;
  // Position des Labels
  @Input() labelPlacement: 'start' | 'end' | 'fixed' | 'stacked' = 'start';

  // Fügt der Komponente automatisch die Klasse 'mits-form-item' hinzu
  @HostBinding('class') class = 'mits-form-item';

  /**
   * Wirft ein Event bei einer Checkbox-Änderung
   * @param event - Das Event der Checkbox-Änderung
   */
  protected onCheckedChage(event: any): void {
    this.checkedChage.emit(event.detail.checked);
  }
}
