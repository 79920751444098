import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { BoilerplateService } from 'src/app/providers/model-services/boilerplate.service';
import { MitsObjectSelectComponent } from '../mits-object-select/mits-object-select.component';
import { BoilerplateModel } from './../../models/settings/boilerplate';

export type BoilerplateCategory =
  | 'Schadensmeldung'
  | 'abgeschlossene Arbeiten'
  | 'ausstehende Arbeiten'
  | 'Inventur'
  | 'Bestellung';

@Component({
  selector: 'mits-boilerplates',
  templateUrl: './mits-boilerplates.component.html',
  styleUrls: ['./mits-boilerplates.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MitsBoilerplatesComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MitsBoilerplatesComponent,
    },
  ],
})
export class MitsBoilerplatesComponent
  implements ControlValueAccessor, Validator
{
  /** Title of the text-field */
  @Input() title: string;
  /** Placeholder oft the text field */
  @Input() placeholder?: string;
  /** Shows the button of the boilerplates */
  @Input() useBoilerplates: BoilerplateCategory;
  /** Size of the textbox, Default: 4 rows */
  @Input() rows = 4;
  /** sets debounce in millisecounds */
  @Input() debounce = 0;
  /** textarea grows with input? */
  @Input() autoGrow = true;
  /** FORM-CONTROL: Has the input got touched? */
  touched = false;
  /** Var for setting a disabled state for validation */
  disabled = false;
  /** Value for the text field */
  value = '';
  /** All useable boilerplates */
  boilerplates: BoilerplateModel[];
  /** is modal with boilerplates currently open? */
  isModalOpen = false;

  @ViewChild('mitsObjectSelectBoilerplates')
  mitsObjectSelectBoilerplates: MitsObjectSelectComponent<BoilerplateModel>;

  constructor(private readonly boilerplateService: BoilerplateService) {}

  /**
   *  By changing the content of the textarea this function is called for validation
   *  @param val New value (if null event gets used)
   *  @param ev Event of changed textarea
   */
  change() {
    this.markAsTouched();
    if (!this.disabled) {
      this.onChange(this.value);
    }
  }

  /**
   * Method for Validator
   * Checks if the element is valid
   * @param control Should the element get disabled
   */
  validate(control: AbstractControl): ValidationErrors {
    return null;
  }

  /**
   * Method for FormControl
   * Gets called by the forms module whenever the parent form wants to set a value in the element
   * @param val new text to set as value for validation
   */
  writeValue(val: any): void {
    this.value = val;
  }

  /**
   * Method for FormControl
   * @param fn OnChange function
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Method for FormControl
   * @param fn OnTouched function
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Method for FormControl
   * Sets if this element of the form should get disabled
   * @param isDisabled Should the element get disabled
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Present the modal for selection of a boilerplate
   * @param event used to prevent to open this modal multiple times
   */
  async presentBoilerplates(event: Event) {
    event.stopPropagation();
    if (
      this.isModalOpen ||
      !this.useBoilerplates ||
      !this.mitsObjectSelectBoilerplates
    )
      return;
    this.isModalOpen = true;
    this.value = this.value ? this.value : '';
    await this.loadBoilerplates();
    this.mitsObjectSelectBoilerplates.setObjects(this.boilerplates);
    this.mitsObjectSelectBoilerplates.openModal().then((boilerplates) => {
      this.addBoilerplates(boilerplates);
      this.isModalOpen = false;
    });
  }

  /**
   * Boilerplates hinzufügen, nachdem man diese ausgewählt hat
   * @param boilerplates to add
   */
  public addBoilerplates(boilerplates: BoilerplateModel[]) {
    if (boilerplates && boilerplates.length > 0) {
      this.value += this.value ? '\n' : '';
      this.value += boilerplates.map((b) => b.description).join('\n');
      this.change();
    }
  }

  /**
   * Method for FormControl: Changed text
   * @param text current text
   */
  private onChange = (text: string) => {};

  /**
   * Method for FormControl: Touched component
   */
  private onTouched = () => {};

  /**
   * Marks this component as touched
   */
  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  /**
   * Loads boilerplates
   */
  private async loadBoilerplates() {
    this.boilerplates = await this.boilerplateService.localWhereWithoutPaging({
      category: this.useBoilerplates || '',
      order_by: 'remote_id ASC',
    });
  }
}
