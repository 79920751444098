import {
  DisplayNameConfig,
  DisplayObject,
} from '../mits-object-select.component';
import { BasicModel } from './../../../models/basic';
import { CompareFunc } from './../mits-object-select.component';

export class MitsObjectSelectHelper<T extends BasicModel> {
  /** last executed search inside select-modal */
  lastSearch = '';

  /**
   * Generates the string to display a human readable name of the object
   * @param obj object to generate string from
   * @returns the string to display the object
   */
  public getDisplayStringOf(obj: T, displayNameVariables: string[]): string {
    if (obj && displayNameVariables?.length > 0) {
      let displayTitle = '';
      displayNameVariables.forEach((dnv) => {
        const val = this.getValue(obj, dnv);
        if (val && val.trim().length > 0) displayTitle += `${val}, `;
      });
      return displayTitle.substring(0, displayTitle.length - 2);
    }
  }

  /**
   * Generates the string to display a human readable name of the object
   * @param obj object to generate string from
   * @returns the strings to display the object
   */
  public getDisplayStringsOf(obj: T, displayNameVariables: string[]): string[] {
    if (obj && displayNameVariables?.length > 0) {
      const displayTitles: string[] = [];
      displayNameVariables.forEach((dnv) => {
        const val = this.getValue(obj, dnv);
        if (val && val.trim().length > 0) displayTitles.push(val);
      });
      return displayTitles;
    }
  }

  /**
   * Gets a value of the object and can call itself to get netsed values
   * @param obj object to get the value from
   * @param keys all keys to iterate through
   * @returns the value of that (next) key
   */
  public getValue(obj: any, keys: string): any {
    if (!keys.includes('.')) {
      if (typeof obj[keys] === 'number') return obj[keys].toString();
      return obj[keys];
    }
    const foundKeys = keys.split('.', 100);
    let currentValue = obj;
    while (foundKeys.length > 0) {
      if (!currentValue) {
        return '';
      }
      currentValue = currentValue[foundKeys[0]];
      foundKeys.shift();
    }
    return currentValue;
  }

  /**
   * Applies the objects to the displayObjects to enable more informations to gets stored temporarily
   * @param objs to apply to the displayObjects
   */
  public applyObjectsToDisplayObjects(
    objs: T[],
    displayNameVariables: DisplayNameConfig
  ): DisplayObject<T>[] {
    if (!objs) return [];

    return objs.map((o) => {
      const dnvKeys = Object.keys(displayNameVariables);
      const displayNameVariablesConverted = {} as DisplayNameConfig;
      dnvKeys.forEach(
        (dnv) =>
          (displayNameVariablesConverted[dnv] = this.getDisplayStringsOf(
            o,
            displayNameVariables[dnv]
          ))
      );
      return {
        object: o,
        displayTexts: displayNameVariablesConverted,
      } as DisplayObject<T>;
    });
  }

  /**
   * Updates the input-field text wich gets displayed as selected object/s
   * @param displayObjects to get the selected object from
   * @param displayNameVariables to get the to line from to display
   */
  public getInputDisplayName(
    displayObjects: DisplayObject<T>[],
    displayNameVariables: DisplayNameConfig
  ): string {
    const objs = this.getSelectedDisplayObjects(displayObjects);
    let newDisplayValue = '';
    objs.forEach((so, i) => {
      newDisplayValue += this.getDisplayStringOf(
        so,
        displayNameVariables.title
      );
      if (i !== objs.length - 1) newDisplayValue += ', ';
    });
    return newDisplayValue;
  }

  /**
   * Sets the displayObjects selected-state if object is given
   * @param objs to set as selected
   */
  public setSelectedDisplayObjects(
    objs: T[],
    displayObjects: DisplayObject<T>[],
    compareFunc?: CompareFunc<T>
  ): DisplayObject<T>[] {
    if (!compareFunc) {
      compareFunc = (o1, o2) => {
        return o1.id === o2.id;
      };
    }
    displayObjects.forEach(
      (o) =>
        (o.selected =
          objs.findIndex((ob) =>
            compareFunc(
              o.object,
              ob,
              this.getDisplayObjectsAsObjects(displayObjects)
            )
          ) >= 0)
    );
    return displayObjects;
  }

  /**
   * Mapps and filters the displayObjects back to the original object type
   * @returns mapped objects
   */
  public getSelectedDisplayObjects(displayObjects: DisplayObject<T>[]): T[] {
    return displayObjects.filter((o) => o.selected).map((o) => o.object);
  }

  /**
   * Unmark all DisplayObjects as selected
   * @param displayObjects
   */
  public unselectDisplayObjects(displayObjects: DisplayObject<T>[]): void {
    displayObjects.forEach((o) => (o.selected = false));
  }

  /**
   * Mapps and filters the displayObjects back to the original object type
   * @returns mapped objects
   */
  public getDisplayObjectsAsObjects(displayObjects: DisplayObject<T>[]): T[] {
    return displayObjects.map((o) => o.object);
  }
}
