
import {CommonModule} from '@angular/common';
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {MitsIconModule} from 'src/app/components/mits-icon/mits-icon.module';

/**
 * Interface that defines the structure for the properties
 * of the MitsPaginationComponent.
 *
 * @prop {number} currentPage - The current active page number.
 * @prop {number} lastPage    - The maximum page number in the pagination.
 */
export interface MitsPaginationProps {
  currentPage: number;
  lastPage: number;
}

@Component({
  selector: 'mits-pagination',
  templateUrl: './mits-pagination.component.html',
  styleUrls: ['./mits-pagination.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, MitsIconModule]
})
export class MitsPaginationComponent {
  /** Current page property from parent component. */
  @Input() currentPage: number;
  /** Last page property from parent component. */
  @Input() lastPage: number;
  /** Debounce time property from parent component. */
  @Input() debounce: number = 500;

  /** Emitter to notify parent of selected page change. */
  @Output() pageChanged = new EventEmitter<number>();

  /** Reference to input element for user-specified page number. */
  @ViewChild('pageInput', { read: ElementRef<HTMLInputElement> })
  pageInputElement: ElementRef<HTMLInputElement>;
  /** Holds the timeout reference for the debounced input. */
  debouncedPageInputTimeout: any;

  /**
   * Getter to determine if the user is on the first page.
   * @returns boolean indicating if current page is the first page.
   */
  get isFirstPage(): boolean {
    return this.currentPage === 1;
  }

  /**
   * Getter to determine if the user is on the last page.
   * @returns boolean indicating if current page is the last page.
   */
  get isLastPage(): boolean {
    return this.currentPage === this.lastPage;
  }

  /**
   * Getter to determine if there's only one page or none at all.
   * @returns boolean indicating if there is only one page or no pages.
   */
  get isOnlyOnePage(): boolean {
    return this.lastPage <= 1;
  }

  /**
   * Handler for when the "Go to First Page" button is clicked.
   * If not on the first page, it emits the first page number.
   */
  clickFirstPageButton(): void {
    if (!this.isFirstPage) {
      this.pageChanged.emit(1);
    }
  }

  /**
   * Handler for when the "Go to Previous Page" button is clicked.
   * If not on the first page, it decrements the page number and emits it.
   */
  clickPrevPageButton(): void {
    if (!this.isFirstPage) {
      this.pageChanged.emit(this.currentPage - 1);
    }
  }

  /**
   * Handler for when the "Go to Next Page" button is clicked.
   * If not on the last page, it increments the page number and emits it.
   */
  clickNextPageButton(): void {
    if (!this.isLastPage) {
      this.pageChanged.emit(this.currentPage + 1);
    }
  }

  /**
   * Handler for when the "Go to Last Page" button is clicked.
   * If not on the last page, it emits the last page number.
   */
  clickLastPageButton(): void {
    if (!this.isLastPage) {
      this.pageChanged.emit(this.lastPage);
    }
  }

  /**
   * Handler for input on the specific page input element.
   * This uses a debounce-mechanism to wait for the user to finish typing
   * before determining if the input is valid and emitting the new page number.
   */
  inputSpecificPage(): void {
    if (this.debouncedPageInputTimeout) {
      clearTimeout(this.debouncedPageInputTimeout);
    }
    this.debouncedPageInputTimeout = setTimeout(async () => {
      const chosenPage = Number(this.pageInputElement.nativeElement.value);
      if (chosenPage >= 1 && chosenPage <= this.lastPage) {
        this.pageChanged.emit(chosenPage);
      }
    }, this.debounce);
  }
}
