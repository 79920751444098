import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';


/**
 * Modell für die ausgewählte Sprache
 */
export interface MitsLanguage {
  // Die Bezeichnung der Sprache (z.B. Englisch)
  language: string;
  // Der Sprachcode (z.B. en)
  languageCode: string;
  // Die Flagge der Sprache (z.B. gb)
  flag: string;
}

/**
 * Service, der die aktuell ausgewählte Sprache der Anwendung aus der URL ermittelt und einen Sprachwechsel ermöglicht
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  // Die aktuelle Sprache der Anwendung
  currentLanguage?: MitsLanguage;
  // Die unterstützten Sprachen der Anwendung
  supportedLanguages: MitsLanguage[] = environment.supportedLanguages;

  constructor() {
    this.setInitialLanguage();
  }

  /**
   * Setzt die Sprache der Anwendung neu
   * @param languageCode die neue Sprache, die gesetzt werden soll
   */
  switchToLanguage(languageCode: string): void {
    if (languageCode !== this.currentLanguage?.languageCode) {
      this.currentLanguage = this.supportedLanguages.find(language => language.languageCode === languageCode);
      this.reloadWithLanguage(languageCode);
    }
  }

  /**
   * Ermittelt die aktuelle Sprache aus der URL
   * @private
   */
  private setInitialLanguage(): void {
    const locale = localStorage.getItem('locale') || 'de';
    localStorage.setItem('locale', locale);
    // Setze die aktuelle Sprache basierend auf dem Sprachcode
    this.currentLanguage = this.supportedLanguages.find(language => language.languageCode === locale);
  }

  /**
   * Startet die Anwendung in der neuen Sprache
   * @param lang - die neue Sprache
   * @private
   */
  private reloadWithLanguage(lang: string): void {
    if(!lang) return;
    localStorage.setItem('locale', lang.toLocaleLowerCase());
    window.location.reload();
  }
}