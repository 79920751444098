import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import {BehaviorSubject, firstValueFrom, Observable, of, Subject} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FunctionSwitchModel } from 'src/app/models/settings/function-switch';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { FULLTEXT_SUBSTRINGS } from 'src/assets/constants/functionSwitch.constants';
import { FunctionSwitchService } from '../model-services/function-switch.service';

@Injectable({
  providedIn: 'root',
})
export class FunctionSwitchHelperService {
  /** All active Function-Switches (including onlyValue Function-Switches) */
  private onlyActiveFunctionSwitches: FunctionSwitchModel[] = [];
  /** Are all Function-Switches loaded? */
  private _isLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoaded: Observable<boolean> = this._isLoaded.asObservable();
  public isLoadingCompleted = false;

  constructor(
    private readonly functionSwitchService: FunctionSwitchService,
    private readonly userHelper: UserHelper,
    private readonly keycloakService: KeycloakService,
  ) {
    this.functionSwitchService.allWithoutPaginate().pipe(
      catchError((error) => {
        if (error.status === 401) {
          console.warn('[FunctionSwitchHelperService] Sitzung abgelaufen, melde ab.');
          this.userHelper.logoutFull();
          this.keycloakService.logout(window.location.origin);
        } else {
          console.error('[FunctionSwitchHelperService] Funktionsschalter können nicht geladen werden:', error);
        }
        this._isLoaded.next(false);
        this.isLoadingCompleted = false;
        return of({data: []});
      })
    ).subscribe((res) => {
      this.onlyActiveFunctionSwitches = res.data.filter((fs) => {
        return fs.active || fs.onlyValue ? fs : null;
      });
      this._isLoaded.next(true);
      this.isLoadingCompleted = true;
    });
  }

  /**
   * Checks if the current user has the rights for this Function
   * @param functionSwitchKey Key of the FunctionSwitch
   * @returns if the current user has the rights for this Function
   */
  public has(functionSwitchKey: string): boolean {
    return this.onlyActiveFunctionSwitches.findIndex((afs) => {
      return afs.key === functionSwitchKey ? afs : null;
    }) >= 0
      ? true
      : false;
  }

  /**
   * Sucht den Wert eines Funktionsschalters in den gecachten, aktiven Funktionschaltern
   * @param functionSwitchKey - Key des Funktionsschalters
   * @returns Wert des Funktionsschalters, oder undefined, wenn der Funktionsschalter nicht existiert
   */
  public value(functionSwitchKey: string): string | undefined {
    const foundSwitch: FunctionSwitchModel = this.onlyActiveFunctionSwitches.find(
      (fs: FunctionSwitchModel): boolean => fs.key === functionSwitchKey
    );
    return foundSwitch && foundSwitch.onlyValue ? foundSwitch.value : undefined;
  }

  /**
   * Laden von mehreren Funktionsschaltern
   * @param functionSwitchKeys to load
   * @returns Loaded FunctionSwitches
   */
  public load(
    functionSwitchKeys: Record<string, string>
  ): Record<string, boolean> {
    const result = {};
    Object.keys(functionSwitchKeys || {}).forEach((key) => {
      const name = functionSwitchKeys[key];
      result[key] = this.has(name);
    });
    return result;
  }

  /**
   * Returns the value for this Function if the switch uses its value
   * @param functionSwitchKey Key of the FunctionSwitch
   * @returns the value for this FunctionSwitch
   * // TODO: Das hier ist eine Online-Funktion. Ggf. Überprüfen und an allen Stellen auswechseln.
   */
  public async getValue(functionSwitchKey: string): Promise<string> {
    const functionSwitches = await firstValueFrom(
      this.functionSwitchService.allWithoutPaginate()
    );
    const foundFS = functionSwitches.data.find(
      (afs) => afs.key === functionSwitchKey
    );
    if (foundFS?.onlyValue) return foundFS.value;
    return null;
  }

  /**
   * @returns the state of the FULLTEXT_SUBSTRING function switch
   */
  public async useSubstringForFullText(): Promise<boolean> {
    if (!this.isLoadingCompleted) {
      await firstValueFrom(this.isLoaded);
    }
    return this.has(FULLTEXT_SUBSTRINGS);
  }
}
