import {CommonModule} from '@angular/common';
import {AfterContentInit, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {buildGridTemplateColumns, getCurrentBreakpoint} from 'src/app/components/mits-data-grid/util/grid-template-columns-generator';
import {MitsDataGridBreakpoints, MitsDataGridColors, MitsDataGridColumnConfig} from './model/';
import {IonicModule} from '@ionic/angular';
import {OrderByParam} from '../../models/order-by-param';
import {Identifiable} from '../../models/basic';
import {MITSHtmlRendererDirective} from '../../directives/html-renderer/html-renterer';

/**
 * Komponente zur Darstellung eines Grids aus Daten (Tabelle)
 * - Basiert auf CSS Grid
 */
@Component({
  selector: 'mits-data-grid',
  templateUrl: './mits-data-grid.component.html',
  styleUrls: ['./mits-data-grid.component.scss'],
    imports: [
        CommonModule,
        IonicModule,
        MITSHtmlRendererDirective,
    ],
  standalone: true
})
export class MitsDataGridComponent<T extends Identifiable> implements AfterContentInit {
  ////////////////// INPUTS
  // Die Daten der Komponente
  @Input() data: T[] | null = null;
  // Die Konfigurationen der Spalte der Tabelle
  @Input() columnConfigs: MitsDataGridColumnConfig<T>[] = [];
  // Der Breakpoint, bei dem die mobile Ansicht aktiviert werden soll (Liste von Karten statt Tabelle) (Standard sm)
  @Input() mobileLayoutBreakpoint: MitsDataGridBreakpoints = 'sm';
  // Zeigt an, ob die Elemente des Grids sichtbar sind (Standard: true)
  @Input() itemsClickable = true;

  ////////////////// OUTPUTS
  // Event-Emitter, der die ID des angeklickten Elements im Grid zurückgibt
  @Output() itemClicked: EventEmitter<number> = new EventEmitter<number>();
  // Input für die Sortierung, die angezeigt werden soll (hat keinen Einfluss auf die tatsächliche Sortierung)
  @Input() orderedByIndicator: OrderByParam = {attribute: '', direction: 'DESC'};
  // Event-Emitter, der die gewünschte Sortierung (Attribut und Richtung) zurückgibt
  @Output() orderBy: EventEmitter<OrderByParam> = new EventEmitter<OrderByParam>();
  //////////////////

  ////////////////// INTERNE VARIABLEN
  // Der aktuell erreichte Schwellenwert der Bildschirmbreite
  currentBreakpoint: MitsDataGridBreakpoints = 'xl';
  // Die Konfiguration für das CSS Grid (grid-template-columns)
  gridTemplateColumns: string | undefined;

  ////////////////// LIFE CYCLE
  /**
   * Wird aufgerufen, wenn der Inhalt initialisiert wurde, bevor die View gerendert wird (wichtig)
   */
  ngAfterContentInit(): void {
    this.currentBreakpoint = getCurrentBreakpoint();
    this.gridTemplateColumns = buildGridTemplateColumns(this.columnConfigs);
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.currentBreakpoint = getCurrentBreakpoint();
    this.gridTemplateColumns = buildGridTemplateColumns(this.columnConfigs);
  }

  /**
   * Emittiert, wonach sortiert werden soll (Attribut und Richtung)
   * @param column - due Spalte, nach der sortiert werden soll (Durch Klick auf die Spaltenüberschrift)
   */
  emitOrderBy(column: MitsDataGridColumnConfig<T>): void {
    if (column.notOrderable) return; // Abbrechen, wenn die Spalte nicht sortierbar ist

    const newDirection: 'ASC' | 'DESC' =
      (this.orderedByIndicator.attribute === column.attribute || this.orderedByIndicator.attribute === column.orderByParam)
      && this.orderedByIndicator.direction === 'DESC' ? 'ASC' : 'DESC';

    const newAttribute: string = column.orderByParam || column.attribute as string;

    this.orderBy.emit({
      attribute: newAttribute,
      direction: newDirection
    });
  }

  /**
   * Gibt den Farbwert für eine Zelle zurück
   * @param row - Die Zeile
   * @param column - Die Spalte
   */
  getColumnColor(row: T, column: MitsDataGridColumnConfig<T>): MitsDataGridColors {
    if(column.color) {
      return column.color(row[column.attribute])?? 'primary'
    }
    return 'primary'
  }
}
