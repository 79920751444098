import { TimeEvent } from '../models';

export const eventConfig: { [index: string]: string } = {
  [TimeEvent.WorkStart]: $localize`Arbeitszeit starten`,
  [TimeEvent.DriveStart]: $localize`Fahren`,
  [TimeEvent.CarEquipStart]: $localize`Fahrzeug rüsten`,
  [TimeEvent.CarEquipEnd]: $localize`Fahrzeug rüsten beenden`,
  [TimeEvent.CarLogout]: $localize`Vom Fahrzeug abmelden`,
  [TimeEvent.BufferstockStart]: $localize`Bufferstock rüsten`,
  [TimeEvent.MachineStart]: $localize`Automat bearbeiten`,
  [TimeEvent.CustomerStart]: $localize`Kundenzeit starten`,
  [TimeEvent.CustomerEnd]: $localize`Kundenzeit beenden`,
  [TimeEvent.IndividualStart]: $localize`Individuelle Zeit starten`,
  [TimeEvent.IndividualEnd]: $localize`Individuelle Zeit beenden`,
  [TimeEvent.Logout]: $localize`Arbeitszeit beenden`,
};
