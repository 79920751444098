
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';
import { AppModule } from './app/app.module';
import { VERSION } from './assets/constants/version.js';
import { environment } from './environments/environment';
import {loadTranslations} from '@angular/localize';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://3a1ba43e6012432d80bad0ad3de44989@o4505148693151744.ingest.sentry.io/4505153395228672',
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    release: VERSION,
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

initLanguage().then(() => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
});


/**
 * Initialisiert die App-Sprache
 * https://github.com/angular/angular/issues/46851#issuecomment-1925507830
 * https://github.com/TheSlimvReal/angular-runtime-translations
 * https://developapa.com/angular-localize/
 */
async function initLanguage(): Promise<void> {
  const locale: string = localStorage.getItem('locale') || 'de';
  if (locale === 'de') return;
  try {
    // JSON-Übersetzungsdatei abrufen
    const translationsPath: string = (environment.basePath?.length > 0 ? '/' : '')
        + environment.basePath
        + '/assets/i18n/messages.'
        + locale
        + '.json'
    const response: Response = await fetch(translationsPath);
    // Überprüfen, ob die Antwort erfolgreich war
    if (!response.ok) console.error(`HTTP-Fehler! Status: ${response.status}`);
    // JSON parsen
    const json: any = await response.json();

    // Übersetzungen initialisieren
    loadTranslations(json.translations);
    $localize.locale = locale;
  } catch (error) {
    console.error('Fehler beim Laden der Übersetzungen:', error);
  }
}