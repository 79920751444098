import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { BasicModel } from 'src/app/models/basic';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { SearchFilterPipe } from '../mits-table/pipes/search-filter';
import { MitsObjectSelectHelper } from './helpers/mits-object-select-helper';
import { MitsObjectSelectModalPage } from './mits-object-select-modal/mits-object-select-modal.page';

export interface DisplayObject<T extends BasicModel> {
  object: T;
  displayTexts: DisplayNameConfig;
  selected?: boolean;
  hidden?: boolean;
}

export interface DisplayNameConfig {
  /** Bold title of the element */
  title: string[];
  /** Italic and thin subtitle under the title */
  subTitle?: string[];
  /** Single variable at the start of the element (only first array-variable) */
  startSlot?: string[];
  /** Single variable at the end of the element (only first array-variable) */
  endSlot?: string[];
}

export type CompareFunc<T> = (
  objListed: T,
  objSelected: T | any,
  allObjects?: T[]
) => boolean;

export type SearchFilterFunc<T> = (
  objects: T[],
  searchText: string
) => Promise<T[]>;

@Component({
  selector: 'mits-object-select',
  templateUrl: './mits-object-select.component.html',
  styleUrls: ['./mits-object-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MitsObjectSelectComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => MitsObjectSelectComponent),
    },
  ],
})
export class MitsObjectSelectComponent<T extends BasicModel>
  implements OnInit, ControlValueAccessor, Validator
{
  /** Show a custom header over the Searchbar inside the object-select */
  @Input() headerTemplate: TemplateRef<any>;
  /** Array of objects wich can get selected by the user */
  @Input() objects: T[];
  /** Array of object-ids wich should be disabled */
  @Input() disabledObjectIds: number[] = [];
  /** The name of the attributes to display the objects name e.g. {title: ['address.city', 'name1']} */
  @Input() displayNameVariables: DisplayNameConfig;
  /** The title shown in the item label */
  @Input() objectTypeTitle =  $localize`Objekt`;
  /** The title shown in the modal header after the objectTypeTitle */
  @Input() modalTitleAttachment = $localize`auswählen`;
  /** The text shown in the cancel button */
  @Input() modalBackButtonText =  $localize`Abbrechen`;
  /** If "true" the input/component will not be visible and it can be used by other buttons to open the selection */
  @Input() shadowInput = false;
  /** Should it be possible to select multiple objects? */
  @Input() enableMultiSelect = false;
  /** If an object gets selected, should the modal automaticly close? */
  @Input() enableCloseAfterSelect = false;
  /** Should the search get automaticly focused after the modal gets opened? */
  @Input() enableAutofocusSearch = false;
  /** If only one object is found, it automaticly gets selected? */
  @Input() enableInstantSelect = false;
  /** If no object is selected should the save-button get disabled? */
  @Input() disableSaveButtonIfNothingSelected = false;
  /** Disables the possibility to deselect an element (disables all selected elements) */
  @Input() disableSelectedElements = false;
  /** Saves the last executed search to load next time opening modal */
  @Input() saveLastSearch = false;
  /** If provided the search inside the modal will have this text as a placeholder */
  @Input() searchPlaceholderText = '';
  /** Volltext noch nicht vearbeitet, zeigt Fehlermeldung in UI an */
  @Input() fulltextRunning: boolean = false;
  /** Selects the search-text if it adds instantly the defined length or more of chars */
  @Input() selectOnPasteLength: number | null = null;
  /** Should the clear-input-button of the searchbar be shown? */
  @Input() showClearInputButton: boolean = true;
  /** Should each information in the subtitles be shown in separate lines? */
  @Input() seperateLinesForSubtitle: boolean = false;
  /** Should the line on the bottom of the item be shown? */
  @Input() lines: boolean = false;
  /** Boolean to choose if objects should be unselected after modal is closed */
  @Input() staySelected: boolean = true;
  /** How should be comared if one object is selected */
  @Input() compareFunc?: CompareFunc<T>;
  /** Handle the search for uself */
  @Input() searchFilter: SearchFilterFunc<T> = (objs: T[], search: string) => {
    return this.searchFilterPipe.transform(objs, search);
  };
  @Input() infinityLoadingService?: OfflineDataService<T>;
  @Input() infinityLoadingArgs?: any = {};
  /** IDs of initially selected Objects; Only used when Infinitiy-Loading Service is provided */
  @Input() infinityPreSelectedSelectedObjectIds: number[] = [];
  /** Gets fired if the selection gets changed by the user */
  @Output() changedSelection: EventEmitter<T | T[]> = new EventEmitter<
    T | T[]
  >();
  /** Should the user be able to click on a disabled element? */
  @Input() enableClickDisabledElement = false;
  /** Event wich gets emitted if the user clicks on a disabled element */
  @Output() clickedDisabledElement?: EventEmitter<DisplayObject<T>> =
    new EventEmitter<DisplayObject<T>>();
  /** All objects but mapped to include more information */
  displayObjects: DisplayObject<T>[];
  /** FLAG: The value wich gets displayed in the inputfield */
  displayValue: string;
  /** FLAG: Is the modal currently open? */
  isModalOpen = false;
  /** FLAG: Was this component touched? */
  touched = false;
  /** FLAG: Is this component disabled */
  disabled = false;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly mitsObjectSelectHelper: MitsObjectSelectHelper<T>,
    private readonly searchFilterPipe: SearchFilterPipe
  ) {}

  ngOnInit(): void {
    this.displayObjects =
      this.mitsObjectSelectHelper.applyObjectsToDisplayObjects(
        this.objects,
        this.displayNameVariables
      );
  }

  /**
   * Set the objects wich should be selectable while component is already initialized
   * @param objs to set as new items
   */
  setObjects(objs: T[]) {
    this.objects = objs;
    this.displayObjects =
      this.mitsObjectSelectHelper.applyObjectsToDisplayObjects(
        this.objects,
        this.displayNameVariables
      );
  }

  /**
   * Setzt die IDs der Objekte, die zwar angezeigt, aber disabled / ausgegraut werden sollen
   * @param ids der Objekte
   */
  setDisabledObjectIds(ids: number[]) {
    this.disabledObjectIds = ids;
  }

  /**
   * Method for Validator
   * Triggers all functions needed after a change
   */
  change() {
    this.markAsTouched();
    if (!this.disabled) {
      const selected = this.mitsObjectSelectHelper.getSelectedDisplayObjects(
        this.displayObjects
      );
      if (this.enableMultiSelect) {
        this.onChange(selected);
        this.changedSelection.emit(selected);
      } else {
        this.onChange(selected[0]);
        this.changedSelection.emit(selected[0]);
      }
      this.updateInputDisplayName();
    }
  }

  /**
   * Method for Validator
   * Checks if the element is valid
   * @param control Should the element get disabled
   */
  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  /**
   * Method for FormControl
   * Gets called by the forms module whenever the parent form wants to set a value in the element
   * @param val new text to set as value for validation
   */
  writeValue(val: any) {
    if (this.enableMultiSelect) {
      if (!val || !Array.isArray(val)) val = [];
      this.displayObjects =
        this.mitsObjectSelectHelper.setSelectedDisplayObjects(
          val,
          this.displayObjects,
          this.compareFunc
        );
    } else {
      if (!val || Array.isArray(val)) val = null;
      this.displayObjects =
        this.mitsObjectSelectHelper.setSelectedDisplayObjects(
          val ? [val] : [],
          this.displayObjects,
          this.compareFunc
        );
    }
    this.updateInputDisplayName();
  }

  /**
   * Method for FormControl
   * @param fn OnChange function
   */
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  /**
   * Method for FormControl
   * @param fn OnTouched function
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * Method for FormControl
   * Sets if this element of the form should get disabled
   * @param isDisabled Should the element get disabled
   */
  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * Method for FormControl
   * Changed text
   * @param object current object
   */
  private onChange = (object: any) => {};

  /**
   * Method for FormControl
   * Touched component
   */
  private onTouched = () => {};

  /**
   * Method for FormControl
   * Marks this component as touched
   */
  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  /**
   * Opens the modal to select the object/s
   * @returns selected objects (if no multiselect => first object)
   */
  async openModal(): Promise<T[]> {
    if (this.isModalOpen || this.disabled) return;
    const preSelectedDisplayObjects = await this.setPreselectedDisplayObjects();
    this.isModalOpen = true;
    const modal = await this.modalCtrl.create({
      component: MitsObjectSelectModalPage,
      componentProps: {
        headerTemplate: this.headerTemplate,
        objectTypeTitle: this.objectTypeTitle,
        modalTitleAttachment: this.modalTitleAttachment,
        modalBackButtonText: this.modalBackButtonText,
        enableMultiSelect: this.enableMultiSelect,
        enableCloseAfterSelect: this.enableCloseAfterSelect,
        enableAutofocusSearch: this.enableAutofocusSearch,
        enableInstantSelect: this.enableInstantSelect,
        displayObjects: this.displayObjects,
        disabledObjectIds: this.disabledObjectIds,
        displayNameVariables: this.displayNameVariables,
        disableSaveButtonIfNothingSelected:
          this.disableSaveButtonIfNothingSelected,
        disableSelectedElements: this.disableSelectedElements,
        saveLastSearch: this.saveLastSearch,
        searchPlaceholderText: this.searchPlaceholderText,
        searchFilter: this.searchFilter,
        infinityLoadingService: this.infinityLoadingService,
        infinityLoadingArgs: this.infinityLoadingArgs,
        fulltextRunning: this.fulltextRunning,
        selectOnPasteLength: this.selectOnPasteLength,
        showClearInputButton: this.showClearInputButton,
        enableClickDisabledElement: this.enableClickDisabledElement,
        seperateLinesForSubtitle: this.seperateLinesForSubtitle,
        selectedObjects: preSelectedDisplayObjects,
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    this.isModalOpen = false;
    if (data?.disabledElementClick) {
      this.clickedDisabledElement.emit(data.disabledElementClick);
      return;
    }
    if (data?.displayObjects) {
      this.displayObjects = data.displayObjects;
      this.change();

      const selectedObjs =
        this.mitsObjectSelectHelper.getSelectedDisplayObjects(
          this.displayObjects
        );
      if (!this.staySelected) {
        // Reset selection for next time
        this.mitsObjectSelectHelper.unselectDisplayObjects(this.displayObjects);
      }
      return selectedObjs;
    }
  }


  /**
   * Sets this.preSelectedDisplayObjects and this.displayObjects in case infinityLoadingService and
   * infinityPreSelectedSelectedObjectIds are provided.
   * @returns preSelectedDisplayObjects - the preselected objects as DisplayObjects or an empty array
   */
  private async setPreselectedDisplayObjects(): Promise<DisplayObject<T>[]> {
    if (this.infinityLoadingService && this.infinityPreSelectedSelectedObjectIds?.length > 0) {
      try {
        const preselectedObjects: T[] = await firstValueFrom(
          this.infinityLoadingService.bulkFind(this.infinityPreSelectedSelectedObjectIds)
        );
        const preSelectedDisplayObjects: DisplayObject<T>[] = this.mitsObjectSelectHelper.applyObjectsToDisplayObjects(
          preselectedObjects,
          this.displayNameVariables
        );
        this.displayObjects = this.mitsObjectSelectHelper.setSelectedDisplayObjects(
          preselectedObjects,
          preSelectedDisplayObjects,
          this.compareFunc
        );
        return preSelectedDisplayObjects;
      } catch (error) {
        console.error('Error setting preSelectedDisplayObjects:', error);
        return [];
      }
    }
  }

  /**
   * Updates the input-field text wich gets displayed as selected object/s
   */
  private updateInputDisplayName(): void {
    this.displayValue = this.mitsObjectSelectHelper.getInputDisplayName(
      this.displayObjects,
      this.displayNameVariables
    );
  }
}
