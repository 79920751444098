import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DataService } from 'src/app/providers/data.service';
import { CarInventoryMovementsModel } from '../models';

/**
 * DataService für die Buchung von Bestandsbewegungen in der Fahrzeuginventur
 * Für die Erzeugung und Anzeige der Fahrzeuginventur wird ein anderer Endpunkt benötigt
 * @see CarInventoriesService
 */
@Injectable({
  providedIn: 'root',
})
export class CarInventoryMovementsService extends DataService<CarInventoryMovementsModel> {
  endpoint = 'car_management/movement/inventory';
  objectName = 'base_movement';

  removeParams = ['client_ok', 'article_name'];

  /**
   * Loads the inventory of the currently signed in car
   * @returns Obserable of backend response
   */
  getInventory(): Observable<CarInventoryMovementsModel> {
    return this.http
      .get<any>(this.endpointWithUrl)
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
