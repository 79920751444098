import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MitsHeaderModule } from 'src/app/components/mits-header/mits-header.module';
import { MitsIconModule } from 'src/app/components/mits-icon/mits-icon.module';
import { SearchFilterPipeModule } from 'src/app/pipes/search-filter/search-filter.module';
import { MitsObjectSelectHelper } from '../helpers/mits-object-select-helper';
import { MitsObjectSelectModalPage } from './mits-object-select-modal.page';
import { MitsScannerButtonModule } from '../../mits-scanner-button/mits-scanner-button.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MitsHeaderModule,
    MitsIconModule,
    SearchFilterPipeModule,
    MitsScannerButtonModule,
    ReactiveFormsModule
  ],
  declarations: [MitsObjectSelectModalPage],
  providers: [MitsObjectSelectHelper],
})
export class MitsObjectSelectModalPageModule {}
