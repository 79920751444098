import {
  Component,
  EnvironmentInjector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AlertController, IonMenu } from '@ionic/angular';
import { FulltextQueueService } from '@vending/sync-engine-client';
import { QueueEntry } from '@vending/sync-engine-client/lib/models/queueEntry';
import { KeycloakService } from 'keycloak-angular';
import { Observable, filter, firstValueFrom, interval, map, take } from 'rxjs';
import { OrderModel } from 'src/app/models/order';
import { LocalDataService } from 'src/app/providers/localStorageData.service';
import { OrderService } from 'src/app/providers/model-services/orders/order.service';
import { PageService } from 'src/app/providers/page-services/page.service';
import { ActiveInventoriesCounterService } from 'src/app/providers/state-services/active-intentories-counter.state.service';
import { ServiceContractsCounterService } from 'src/app/providers/state-services/service-contracts-counter.service';
import { INVENTORY_SCHEDULES_BASE_ROUTE } from 'src/packages/inventorySchedules/inventorySchedules.routes';
import {
  TimeEvent,
  TimelogHelperV2,
  TimelogState,
} from 'src/packages/timeManagement';
import { StockObjectModel } from 'src/packages/warehouse';
import { StockObjectService } from 'src/packages/warehouse/providers/stockObject.service';
import {
  CAR_MANAGEMENT,
  DAMAGE_REPORTS_MACHINE,
  DAMAGE_REPORTS_ORDER,
  LG_VERIFY_ORDER_OF_BUFFERSTOCK,
  SA_CAN_BE_CREATED,
  SO_USE_BUFFERSTOCKS,
  TOUR_MANAGEMENT,
  ZE_AUTO_DAYEND,
  ZE_AUTO_DAYSTART,
  ZE_AUTO_WORKING_AFTER_CRASH,
  ZE_ENABLE_CORRECTIONS,
  ZE_FOR_USER_OR_CAR,
  ZE_SHOW_TIMETRACKING_IN_MENU,
} from '../assets/constants/functionSwitch.constants';
import { VERSION } from '../assets/constants/version';
import { BusinessModel } from './models/settings/business';
import { MandatorModel } from './models/settings/mandator';
import { ISideMenu } from './models/sidemenu/sidemenu';
import {
  IMenuBasicElement,
  ISideMenuElement,
} from './models/sidemenu/sidemenu-element';
import { AccessGuard } from './providers/access.guard';
import { FunctionSwitchHelperService } from './providers/component-helpers/function-switch.service';
import { ToastService } from './providers/component-helpers/toast.service';
import { EventsService } from './providers/events.service';
import { ErrorLogHelper } from './providers/helpers/errorlogs-helper.service';
import { InitializationHelper } from './providers/helpers/initialization-helper';
import { LoadingHelper } from './providers/helpers/loading-helper.service';
import { SystemHelper } from './providers/helpers/system-helper';
import { UserHelper } from './providers/helpers/user-helper.service';
import { SyncWrapperService } from './providers/syncWrapper.service';
import {INVENTORIES_BASE_ROUTE} from './pages/backend/inventories/routes';
import {environment} from '../environments/environment';

// The Pathname for the Home-Page depending on the environment
const HOME_PATHNAME = '/' + [environment.basePath, 'home-page'].filter(Boolean).join('/')

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  /** Version der App (Jahr*.Kalenderwoche*.Minor) *Zeitpunkt des Sprintstarts */
  readonly version: string = VERSION;
  /** Hier wird das komplette Side-Menu definiert */
  sideMenu: ISideMenu;
  /** Momentan ausgewählte/geöffnete Seite/Page */
  selectedPage: string;
  /** Ausgewählter Mandant */
  selectedMandator: MandatorModel;
  /** Ausgewähltes Business */
  selectedBusiness: BusinessModel;
  /** Interval for loading errorlogs (interval depends on user-group) */
  intervalErrorlog: any;
  /** Interval for loading orders count */
  intervalReloadOrders: any;
  /** Subscription for user-loaded event */
  subscriptionUserLoaded: any;
  /** Subscriptions wich need to get unsubscribed */
  _subscriptions: any[] = [];
  /** can create Order from SideMenu */
  _canCreateOrderFromSideMenu: boolean;
  /** How many Fulltextentries are queued */
  _fulltextEntriesQueued: number = 0;
  /** Fulltext entries listener observable */
  private fulltextEntriesTimer: Observable<number>;
  /** LocalDataService for Orders */
  private localOrderService : LocalDataService<OrderModel> = new LocalDataService<OrderModel>('order');

  @ViewChild('ionicMenu') ionicMenuComponent: IonMenu;

  private appConfigured = false;
  private _usesDamagereportsOnOrders: boolean;
  private _usesDamagereportsOnMachines: boolean;
  private _timetrackingEnabled: boolean;
  private _showTimetrackingInMenu: boolean;
  private _canCorrectTimelogs: boolean;
  private _carManagementActive: boolean;
  private _useBufferstocks: boolean;
  private _tourmanagementEnabled: boolean;
  private _automaticTimelogStart: boolean;
  private _automaticTimelogEnd: boolean;
  public _needsVerificationForOrder: boolean;

  constructor(
    public readonly enviromentInjector: EnvironmentInjector,
    public readonly errorLogHelper: ErrorLogHelper,
    public readonly toastService: ToastService,
    public readonly systemHelper: SystemHelper,
    public readonly userHelper: UserHelper,
    private readonly accessGuard: AccessGuard,
    private readonly alertCtrl: AlertController,
    private readonly events: EventsService,
    private readonly functionSwitchHelper: FunctionSwitchHelperService,
    private readonly fulltextQueueService: FulltextQueueService,
    private readonly initializationHelper: InitializationHelper,
    private readonly keycloakService: KeycloakService,
    private readonly loadingHelper: LoadingHelper,
    private readonly pageService: PageService,
    private readonly router: Router,
    private readonly swUpdate: SwUpdate,
    private readonly syncWrapperService: SyncWrapperService,
    private readonly timelogHelper: TimelogHelperV2,
    private readonly stockObjectService: StockObjectService,
    private readonly orderService: OrderService,
    private readonly activeInventoriesCounterService: ActiveInventoriesCounterService,
    private readonly serviceContractsCounterService: ServiceContractsCounterService
  ) {
    const swUpdateSub = this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.debug(
            `[AppComponent] Downloading new app version: ${evt.version.hash}`
          );
          break;
        case 'VERSION_READY':
          console.debug(
            '[AppComponent] Update ready from "',
            evt.currentVersion.hash,
            '" to "',
            evt.latestVersion.hash,
            '"'
          );
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.debug(
            `[AppComponent] Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });
    this._subscriptions.push(swUpdateSub);

    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map((evt) => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))
    );

    const updateAvailSub = updatesAvailable.subscribe((evt) => {
      console.debug(
        '[AppComponent] Update available from "',
        evt.current,
        '" to "',
        evt.available,
        '"'
      );
      if (evt.current.hash != evt.available.hash) {
        this.systemHelper.isVersionOutdated = true;
        this.systemHelper.versionDetails = {
          current: evt.current.hash,
          latest: evt.available.hash,
        };
      }
    });
    this._subscriptions.push(updateAvailSub);
  }

  async ngOnInit() {
    await this.systemHelper.initializeNetworkCheck();
    this.subscriptionUserLoaded = this.events
      .subscribe('userHelper:loadedUser')
      .subscribe(async () => {
        this.startInitialization();
        this.subscriptionUserLoaded.unsubscribe();
      });
    this.events.subscribe('logout').subscribe(() => {
      this.logout();
    });
    this.events
      .subscribe('userHelper:loadedUser')
      .pipe(take(1))
      .subscribe(async (): Promise<void> => {
        const userId: number = this.userHelper.getUser()?.id;
        await this.initializeCounterServices(userId);
      });
    this.fulltextEntriesTimer = interval(10 * 1000);
    const fulltextEntriesSub = this.fulltextEntriesTimer.subscribe(async () => {
      this._fulltextEntriesQueued = await this.fulltextQueueService.count();
    });
    this._subscriptions.push(fulltextEntriesSub);
    await this.buildAndSetAccess();
    await this.handleTimeStates();
    this.initializeCurrentPageListener();
  }

  /**
   * Überprüft, ob aufgrund einiger Funktionsschalter Änderungen in den Zeiteinträgen vorgenommen
   * werden sollen und führt diese aus.
   */
  async handleTimeStates() {
    const currentState = this.timelogHelper.currentState;
    if (currentState === TimelogState.Working) return;
    const appCrashed = currentState != TimelogState.Unkown;

    const forceState = this.functionSwitchHelper.has(
      ZE_AUTO_WORKING_AFTER_CRASH
    );
    if (appCrashed && forceState) {
      const stateChanged = await this.timelogHelper.forceState(
        TimelogState.Working
      );
      if (stateChanged)
        this.toastService.custom(
          $localize`Die App wurde unplanmäßig beendet. Sie befinden sich jetzt wieder in der Arbeitszeit`,
          'warning',
          5000
        );
    } else if (this._automaticTimelogStart) {
      // Hier gibt es noch einen Zwischenschritte für den Tagesstart
      await this.timelogHelper.changeStateDebounce(TimeEvent.WorkStart);
    }
  }

  ngOnDestroy() {
    this.systemHelper.networkListener.remove();
    clearInterval(this.intervalErrorlog);
    clearInterval(this.intervalReloadOrders);
    this._subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private async startInitialization() {
    this.syncWrapperService.startSync();
    await this.initializationHelper.runInitialization();
  }

  /**
   * Eine Page öffnen
   */
  openPage(menuElement: ISideMenuElement): void {
    if (menuElement.onAction) {
      menuElement.onAction();
    }
    if (menuElement.routerLink) {
      if (
        this.pageService.deviceTypes.ios ||
        this.pageService.deviceTypes.android
      ) {
        this.ionicMenuComponent.close();
      }
      this.router.navigateByUrl(menuElement.routerLink);
    }
  }

  /**
   * Sichtbarkeit von Subelementen wechseln
   */
  changeSubElementsVisible(menuElement: ISideMenuElement): void {
    menuElement.subelementsVisible = !menuElement.subelementsVisible;
  }

  /**
   * Compare Methode für die Ion-Selects
   * @param o1 Option 1
   * @param o2 Option 2
   * @returns ob die Optionen gleich sind
   */
  compareWith(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  changedBusiness(): void {
    const success = this.userHelper.setBusiness(this.selectedBusiness);
    if (!success) {
      this.selectedBusiness = this.userHelper.getManBus().business;
    }
  }

  /**
   * Initializes the listener for new routes to display as current page
   */
  private initializeCurrentPageListener(): void {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe({
        next: async (v: NavigationEnd) => {
          this.checkAndSetCurrentPage(v.urlAfterRedirects);
        },
      });
  }

  /**
   * Simplifys the url so no params or domain is present
   * @param url to simplify
   * @returns simplified url-string
   */
  private simplifyUrl(url: string): string {
    if (url.startsWith('/')) url = url.substring(1);
    return url.split('?', 1)[0];
  }

  /**
   * Checks and sets for same page url on an element
   * @param e Menu-Element to check
   * @param url to check for inside element
   */
  private checkSamePage(e: IMenuBasicElement, url: string) {
    if (this.checkForURLMatch(url, e)) {
      if (e.name) this.selectedPage = e.name;
    }
  }

  /**
   * Gibt zurück, ob der Nutzer sich auf der Startseite befindet.
   */
  get isHomePage(): boolean {
    return window.location.pathname === HOME_PATHNAME
  }

  private checkElementForPage(elements: ISideMenuElement[], url: string) {
    // Gesondert behandeln, da übergebene URL vom Angular-Router stammt und nicht der Startseiten-URL entspricht
    if (this.isHomePage) {
      this.selectedPage = 'home-page';
      return;
    }
    elements.forEach((e) => {
      this.checkSamePage(e, url);
      if (e.subElements && e.subElements.length > 0) {
        this.checkElementForPage(e.subElements, url);
      }
    });
  }

  /**
   * Checks if the url is valid and sets the current page correctly
   * @param url URL to set for current page
   * @returns if side was found with this url
   */
  private checkAndSetCurrentPage(url: string): void {
    if (!this.sideMenu || !url) return;
    url = this.simplifyUrl(url);
    this.sideMenu.groups.forEach((g) => {
      this.checkElementForPage(g.elements, url);
    });
  }

  /**
   * Checks if URL is used inside the elements routerLink
   * @param url URL to check
   * @param element element to compare
   * @returns True / False
   */
  private checkForURLMatch(url: string, element: ISideMenuElement): boolean {
    if (!url || !element?.routerLink) return false;
    return element.routerLink.includes(url);
  }

  private async configureApp(): Promise<void> {
    this._canCreateOrderFromSideMenu =
      this.functionSwitchHelper.has(SA_CAN_BE_CREATED);
    this._usesDamagereportsOnOrders =
      this.functionSwitchHelper.has(DAMAGE_REPORTS_ORDER);
    this._usesDamagereportsOnMachines = this.functionSwitchHelper.has(
      DAMAGE_REPORTS_MACHINE
    );
    this._timetrackingEnabled =
      this.functionSwitchHelper.has(ZE_FOR_USER_OR_CAR);
    this._showTimetrackingInMenu = this.functionSwitchHelper.has(
      ZE_SHOW_TIMETRACKING_IN_MENU
    );
    this._canCorrectTimelogs = this.functionSwitchHelper.has(
      ZE_ENABLE_CORRECTIONS
    );
    this._carManagementActive = this.functionSwitchHelper.has(CAR_MANAGEMENT);
    this._useBufferstocks = this.functionSwitchHelper.has(SO_USE_BUFFERSTOCKS);
    this._needsVerificationForOrder = this.functionSwitchHelper.has(
      LG_VERIFY_ORDER_OF_BUFFERSTOCK
    );
    this._tourmanagementEnabled =
      this.functionSwitchHelper.has(TOUR_MANAGEMENT);
    this._automaticTimelogStart =
      this.functionSwitchHelper.has(ZE_AUTO_DAYSTART);
    this._automaticTimelogEnd = this.functionSwitchHelper.has(ZE_AUTO_DAYEND);

    await this.loadData();

    if (this.userHelper.getUser()?.is_admin) {
      if (!this.intervalErrorlog)
        this.intervalErrorlog = setInterval(() => {
          this.errorLogHelper.reloadErrorlogs();
        }, 60000);
      else this.errorLogHelper.reloadErrorlogs();
    }
    this.buildSideMenu();
    await this.setAccess();
    this.checkAndSetCurrentPage(this.router.url);
    this.appConfigured = true;
  }

  /**
   * Baut das Seitenmenü und setzt wenn möglich die Rechte
   */
  private async buildAndSetAccess(): Promise<void> {
    if (this.appConfigured) {
      await this.configureApp();
    } else {
      if (!this.functionSwitchHelper.isLoadingCompleted)
        await firstValueFrom(this.functionSwitchHelper.isLoaded);
      await this.configureApp();
    }
  }

  /**
   * Lädt alle für das Side-Menu wichtigen Daten
   * Wählt wenn vorhanden schon einen Mandanten und eine Betriebsstätte
   */
  private async loadData(): Promise<void> {
    const resUser = await this.userHelper.loadUser();
    this.selectedMandator = resUser.mandator;
    if (resUser.businesses?.length > 0) {
      this.selectedBusiness = resUser.businesses[0];
    }
  }

  /**
   * Funktion um den aktuellen Benutzer auszuloggen
   */
  private async logout(): Promise<void> {
    // Bereinigen des Local-Storages von zwischengespeicherten Aufträgen
    this.localOrderService.deleteAll();
    const proceed: boolean = await this.assureQueueEmptyBeforeLogout();
    if (!proceed) return;
    if (this._automaticTimelogEnd) {
      await this.timelogHelper.changeStateDebounce(TimeEvent.Logout);
      await this.directLogout();
    } else if (this.timelogHelper.currentState !== TimelogState.Unkown) {
      await this.timelogAlertBeforeLogout();
    } else {
      await this.directLogout();
    }
  }

  private async directLogout() {
    this.userHelper.logoutFull();
    await this.keycloakService.logout();
  }

  /**
   * Menü aufbauen
   */
  private buildSideMenu(): void {
    this.sideMenu = {
      headerTitle: $localize`Hauptmenü`,
      groups: [
        {
          title: '',
          elements: [
            {
              title: $localize`Startseite`,
              icon: 'home-outline',
              routerLink: 'home-page',
              name: 'home-page',
              offlineSupported: true,
              accessGranted: false,
            },
          ],
        },
        {
          title: $localize`Bearbeitung`,
          elements: [
            {
              title: $localize`Schnelleinstieg`,
              icon: 'scan',
              routerLink: 'quick-start',
              name: 'fast-entrance',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: this.userHelper.isFiller
                ? $localize`Aktive Befüllungen`
                : $localize`Aktive Aufträge`,
              icon: 'document-text-outline',
              routerLink: 'orders/active',
              name: 'orders-active',
              offlineSupported: true,
              accessGranted: false,
              badge: {
                color: 'primary',
                count$:
                  this.serviceContractsCounterService.getActiveServiceContractsAsObservable(),
              },
            },
            {
              title: this.userHelper.isFiller
                ? $localize`Geplante Tour`
                : $localize`Serviceaufträge`,
              icon: 'document-text-outline',
              routerLink: 'orders/overview',
              name: 'orders-overview',
              offlineSupported: true,
              accessGranted: false,
              badge: {
                color: 'primary',
                count$:
                  this.serviceContractsCounterService.getOpenServiceContractsAsObservable(),
              },
            },
            {
              title: $localize`Aktive Inventuren`,
              icon: 'newspaper-outline',
              routerLink: 'inventories/active',
              name: 'inventories-active',
              offlineSupported: true,
              accessGranted: false,
              badge: {
                color: 'primary',
                count$:
                  this.activeInventoriesCounterService.getActiveInventoriesAsObservable(),
              },
              hidden: !this._useBufferstocks,
            },
            {
              title: $localize`Auftrag erstellen`,
              icon: 'document-attach-outline',
              routerLink: 'emergency-order-new',
              name: 'order-create-emergency',
              offlineSupported: true,
              accessGranted: false,
              hidden: !this._canCreateOrderFromSideMenu,
            },
          ],
        },
        {
          title: $localize`Stammdaten`,
          elements: [
            {
              title: $localize`Kunden`,
              icon: 'people-outline',
              routerLink: 'customers',
              name: 'customers',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: $localize`Maschinen`,
              icon: 'cafe-outline',
              routerLink: 'machines',
              name: 'machines',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: $localize`Autos`,
              icon: 'car-sport-outline',
              routerLink: 'cars',
              name: 'cars',
              offlineSupported: true,
              accessGranted: false,
              hidden: !this._carManagementActive,
            },
            {
              title: $localize`Zeiterfassung`,
              icon: 'stopwatch-outline',
              routerLink: 'timelogs',
              name: 'timelogs',
              offlineSupported: true,
              accessGranted: false,
              hidden:
                !this._timetrackingEnabled || !this._showTimetrackingInMenu,
            },
            {
              title: $localize`Schadensmeldungen`,
              icon: 'trash-bin-outline',
              routerLink: 'damageManagement/damage_reports',
              name: 'damage_reports',
              offlineSupported: true,
              accessGranted: false,
              hidden: !(
                this._usesDamagereportsOnMachines ||
                this._usesDamagereportsOnOrders
              ),
            },
          ],
        },
        {
          title: $localize`Verwaltung`,
          hidden: !(this.userHelper.isInside || this.userHelper.isAdmin),
          elements: [
            {
              title: 'Dashboard',
              icon: 'apps-outline',
              routerLink: 'dashboard-inside',
              name: 'dashboard-inside',
              accessGranted: false,
            },
            {
              title: $localize`Serviceaufträge`,
              icon: 'document-text-outline',
              routerLink: 'service_contracts',
              name: 'service-contracts',
              accessGranted: false,
            },
            {
              title: $localize`Korrekturanfragen`,
              icon: 'stopwatch-outline',
              routerLink: 'timelog-corrections',
              name: 'timelog-corrections',
              hidden: !(this._timetrackingEnabled && this._canCorrectTimelogs),
              accessGranted: false,
            },
            {
              title: $localize`Bestellanfragen`,
              icon: 'cart-outline',
              routerLink: 'warehouse/orders',
              name: 'warehouse-orders',
              hidden:
                !this._useBufferstocks || !this._needsVerificationForOrder,
              accessGranted: false,
            },
            {
              title: $localize`Einstellungen`,
              icon: 'cog-outline',
              routerLink: 'settings',
              name: 'settings',
              accessGranted: false,
            },
            {
              title: $localize`Tourenplanung`,
              icon: 'map-outline',
              routerLink: 'tour-management',
              name: 'tour-management',
              hidden: !this._tourmanagementEnabled,
              accessGranted: false,
            },
            {
              title: $localize`Inventurplanung`,
              icon: 'calendar-outline',
              routerLink: INVENTORY_SCHEDULES_BASE_ROUTE,
              name: INVENTORY_SCHEDULES_BASE_ROUTE,
              accessGranted: false,
            },
            {
              title: $localize`Inventuren`,
              icon: 'clipboard-outline',
              routerLink: INVENTORIES_BASE_ROUTE,
              name: INVENTORIES_BASE_ROUTE,
              accessGranted: false,
            },
          ],
        },
        {
          title: $localize`Optionen`,
          cssClass: 'bottom',
          elements: [
            {
              title: $localize`Warteschlange`,
              icon: 'albums-outline',
              routerLink: 'dashboard-worker',
              name: 'dashboard-worker',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: $localize`Hilfe`,
              icon: 'help-outline',
              routerLink: 'help',
              name: 'help',
              offlineSupported: true,
              accessGranted: false,
            },
            {
              title: $localize`Abmelden`,
              icon: 'log-out-outline',
              color: 'danger',
              offlineSupported: true,
              accessGranted: true,
              onAction: () => {
                this.logout();
              },
            },
          ],
        },
      ],
    };
  }

  /**
   * Setzt Berechtigungen im Menü
   */
  private async setAccess(): Promise<void> {
    this.sideMenu.groups.forEach(async (group) => {
      group.elements.forEach(async (element) => {
        let hasRoles =
          element.rolesNeeded && element.rolesNeeded.length > 0
            ? element.rolesNeeded
                .map((role) => this.accessGuard.hasRight(role))
                .every((r) => r)
            : true;
        let hasAccessToRoute = await this.accessGuard.hasAccessToPath(
          element.routerLink
        );
        element.accessGranted = hasRoles && hasAccessToRoute;
        if (element.accessGranted) {
          group.accessGranted = true;
        }
      });
    });
  }

  /**
   * Test beim Logout ob nnoch Einträge in Warteschlange + 5sek warten nach upload (#748)
   * @private
   */
  private async assureQueueEmptyBeforeLogout(): Promise<boolean> {
    let queuedData: QueueEntry<any>[] =
      await this.syncWrapperService.queuedData();
    if (!queuedData || queuedData.length === 0) return true;
    this.loadingHelper.setText($localize`Warteschlange wird geleert...`);
    this.loadingHelper.addLoading();
    await this.syncWrapperService.startUploadedQueue();
    await new Promise((resolve) => setTimeout(resolve, 5000));
    this.loadingHelper.removeLoading();
    return this.showQueueNotEmptyAlert();
  }

  /**
   * Rückfrage beim Logout, falls die Warteschlange noch Einträge beinhaltet (#748)
   * @private
   */
  private async showQueueNotEmptyAlert(): Promise<boolean> {
    const queuedData = await this.syncWrapperService.queuedData();
    if (!queuedData || queuedData.length === 0) return true;
    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header: $localize`Noch Daten in der Warteschlange`,
      message: $localize`Wirklich abmelden oder Warteschlange ansehen?`,
      buttons: [
        {
          text: $localize`Warteschlange ansehen`,
          handler: () => {
            alert.dismiss().then(() => {
              this.router.navigateByUrl('dashboard-worker');
            });
            return false;
          },
        },
        {
          text: $localize`Abmelden`,
          role: 'cancel',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role === 'cancel';
  }

  /**
   * Rückfrage beim Logout, ob in Zeiterfassung gewechselt werden soll
   * @private
   */
  private async timelogAlertBeforeLogout(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: $localize`Zeiterfassung aktiv`,
      message: $localize`Wirklich abmelden oder in Zeiterfassung wechseln?`,
      buttons: [
        {
          text: $localize`Zeiterfassung`,
          handler: () => {
            this.router.navigateByUrl('timelogs');
          },
        },
        {
          text: $localize`Abmelden`,
          handler: async () => {
            await this.directLogout();
          },
        },
      ],
    });
    await alert.present();
  }

  /**
   * Funktion um die Services für die Counter zu initialisieren
   * @private
   */
  public async initializeCounterServices(userId: number): Promise<void> {
    // Initialisierung des ActiveInventoriesCounterService
    await this.stockObjectService
      .allByInventoryRequired(true)
      .then(async (stocksWithInventoryRequired: StockObjectModel[]) => {
        await this.activeInventoriesCounterService.initialState(
          stocksWithInventoryRequired,
          userId
        );
      })
      .catch((error): void => {
        console.error(
          'Error initializing ActiveInventoriesCounterService',
          error
        );
      });
    // Initialisierung des ServiceContractsCounterService
    await firstValueFrom(this.orderService.forUser())
      .then(async (orders: OrderModel[]) => {
        await this.serviceContractsCounterService.initialState(orders, userId);
      })
      .catch((error): void => {
        console.error(
          'Error initializing ServiceContractsCounterService',
          error
        );
      });
  }
}
