<ion-item lines="full" [disabled]="disabled">
    <ng-container *ngIf="readonly; else ionCheckbox">
        <ion-label position="floating">{{ label }}</ion-label>
        <ion-icon [color]="checked? 'success' : 'danger' " slot="end"
                  [name]="checked? 'checkmark-circle' : 'close-circle'"></ion-icon>
    </ng-container>

    <ng-template #ionCheckbox>
        <ion-label [position]="labelPlacement">{{ label }}</ion-label>
        <ion-checkbox [checked]="checked" (ionChange)="onCheckedChage($event)"
                      [disabled]="disabled">
        </ion-checkbox>
    </ng-template>
</ion-item>
