import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';
import {IndexDataFunction} from 'src/app/providers/page-services/index-data/index-data.interface';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    delay,
    distinctUntilChanged,
    filter,
    from,
    map,
    Observable,
    of,
    startWith,
    Subject,
    switchMap,
    tap,
    withLatestFrom
} from 'rxjs';
import {take} from 'rxjs/operators';
import {Identifiable} from '../../../models/basic';
import {OrderByParam} from '../../../models/order-by-param';


@Injectable()
export class IndexDataService<T extends Identifiable> {
    ///////// INPUTS
    // FormControl für die Sucheingabe
    searchField: FormControl<string> = new FormControl<string>('', {nonNullable: true});
    // FormControl für die aktuelle Seite
    currentPage: FormControl<number> = new FormControl<number>(1, {nonNullable: true});
    // FormControl für die Sortierung
    sortField: FormControl<OrderByParam> = new FormControl<OrderByParam>({
        attribute: 'id',
        direction: 'DESC'
    }, {nonNullable: true});

    ///////// OUTPUTS
    // BehaviorSubject, das die geladenen Daten enthält
    private _data$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    data$: Observable<T[]> = this._data$.asObservable();
    // Observable, das anzeigt, ob es noch mehr Seiten gibt
    private _hasMorePages$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    hasMorePages$: Observable<boolean> = this._hasMorePages$.asObservable();
    // Observable, das die letzte verfügbare Seite anzeigt
    private _lastPage: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    lastPage$: Observable<number> = this._lastPage.asObservable();
    // BehaviorSubject für den Ladezustand
    private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoading$: Observable<boolean> = this._isLoading$.asObservable();
    // Subject, dass nutzende Komponenten über Fehler informiert
    private _error$: Subject<void> = new Subject<void>();
    error$: Observable<void> = this._error$.asObservable();

    /**
     * Initialisiert die Datenquelle mit einer Funktion zur Datenbereitstellung
     * @param dataFunction Die Funktion, die die Daten liefert
     * @param loadingType - Gibt an, ob die Daten mit Paginierung oder Infinite-scroll geladen werden sollen
     */
    init(dataFunction: IndexDataFunction<T>, loadingType: 'pagination' | 'infinite-scroll' = 'infinite-scroll'): void {
        combineLatest([
            // Überwache die Sucheingabe
            this.searchField.valueChanges.pipe(
                startWith(''), // Beginne mit leerem Suchbegriff
                distinctUntilChanged(), // Ignoriere, wenn der Wert sich nicht ändert
                // Debounce von 500ms bei jeder Sucheingabe außer initialem Wert ''
                switchMap((searchTerm: string, index: number) => {
                    return index === 0 ? of(searchTerm) : of(searchTerm).pipe(delay(500));
                }),
                tap(() => {
                    // Setze die aktuelle Seite zurück bei neuer Sucheingabe
                    this.currentPage.setValue(1);
                }),
            ),
            // Überwache Seitenwechsel
            this.currentPage.valueChanges.pipe(
                startWith(1), // Beginne mit Seite 1
                withLatestFrom(this.lastPage$), // Ziehe den aktuellen Wert der letzten Seite mit heran
                filter(([page, lastPage]) => page <= lastPage), // Ignoriere Seiten, die den Gültigkeitsbereich überschreiten
                map(([page]) => page), // Entferne die lastPage wieder aus dem Stream
            ),
            // Überwache Sortierparameter
            this.sortField.valueChanges.pipe(
                startWith({attribute: 'id', direction: 'DESC'} as OrderByParam), // Füge Sortierparameter hinzu
                distinctUntilChanged(), // Ignoriere, wenn der Wert sich nicht ändert
                tap(() => {
                    // Setze die aktuelle Seite zurück bei neuer Sortierung
                    this.currentPage.setValue(1);
                }),
            )
        ])
            .pipe(
                // Lade die Daten entsprechend der Suche und Seite
                switchMap(([searchTerm, page, sort]) => {
                    this._isLoading$.next(true);
                    return this.fetchData(dataFunction, searchTerm, page, sort)
                        .pipe(
                            // Fange Fehler beim Laden der Daten ab
                            catchError((error: unknown) => {
                                console.error('[IndexDataService][init]: Fehler beim Laden der Daten', error);
                                this._isLoading$.next(false);
                                this._error$.next();
                                return of({data: [], currentPage: 1, lastPage: 1});
                            })
                        );
                })
            )
            .subscribe(({data, currentPage, lastPage}) => {
                // Setze die Daten auf ein leeres Array zurück, wenn die erste Seite geladen wird, oder 'pagination'
                // statt 'infinite-scroll' verwerndet wird
                const previousData: T[] = (currentPage === 1 || loadingType === 'pagination') ? [] : this._data$.value;
                // Aktualisiere das Observable für die Daten
                this._data$.next([...previousData, ...data]);
                // Aktualisiere das Observable für die letzte verfügbare Seite
                this._lastPage.next(lastPage);
                // Aktualisiere das Observable, das anzeigt, ob noch weitere Daten geladen werden können
                this._hasMorePages$.next(currentPage < lastPage);
                // Aktualisiere das Observable das den Ladezustand anzeigt
                this._isLoading$.next(false);
            });
    }

    /**
     * Lädt die nächste Seite und gibt ein Observable zurück
     */
    loadNextPage(): Observable<boolean> {
        const nextPage = this.currentPage.value + 1;
        return this.loadPage(nextPage);
    }

    /**
     * Lädt die vorherige Seite und gibt ein Observable zurück
     */
    loadPreviousPage(): Observable<boolean> {
        const previousPage = this.currentPage.value > 1 ? this.currentPage.value - 1 : 1;
        return this.loadPage(previousPage);
    }

    /**
     * Lädt eine spezifische Seite und gibt ein Observable zurück
     * @param page Die spezifische Seite, die geladen werden soll
     */
    loadSpecificPage(page: number): Observable<boolean> {
        return this.loadPage(page);  // Verwende die Hilfsmethode
    }

    /**
     * Setzt die aktuelle Seite zurück auf 1
     */
    resetPage(): Observable<boolean> {
        return this.loadPage(1);  // Verwende die Hilfsmethode
    }

    /**
     * Neuladen der aktuellen Seite
     */
    reload(): Observable<boolean> {
        return this.loadPage(this.currentPage.value); // Verwende die vorhandene Logik, um dieselbe Seite erneut zu laden
    }

    /**
     * Methode zum Laden der Daten basierend auf der bereitgestellten Funktion
     * @param dataFunction Die Funktion, die die Daten liefert
     * @param search Suchbegriff
     * @param page Aktuelle Seite
     * @param sort - Sortierparameter
     */
    private fetchData(
        dataFunction: IndexDataFunction<T>,
        search: string,
        page: number,
        sort: OrderByParam
    ): Observable<{ data: T[]; lastPage: number; currentPage: number }> {
        return from(dataFunction(page, search, sort)).pipe(
            map(result => ({
                data: result.data || [],
                currentPage: result.pagy.page ?? 1,
                lastPage: result.pagy.last ?? 1,
            }))
        );
    }

    /**
     * Hilfsmethode zum Laden einer spezifischen Seite
     */
    private loadPage(page: number): Observable<boolean> {
        this.currentPage.setValue(page);  // Setze die neue Seite
        return this.isLoading$.pipe(
            filter(isLoading => !isLoading), // Nur fortfahren, wenn nicht geladen wird
            take(1)
        );
    }
}
