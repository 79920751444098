import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SyncProcessorService,
  SyncQueueService,
} from '@vending/sync-engine-client';
import { QueueEntry } from '@vending/sync-engine-client/lib/models/queueEntry';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, catchError, retry, tap, of } from 'rxjs';
import { toFormData } from 'src/app/components/mits-files-upload/mits-files-upload-select/helpers/formDataParser';
import { OrderModel } from 'src/app/models/order';
import { ResponseModel } from 'src/app/models/response';
import { ServiceContractsCounterService } from 'src/app/providers/state-services/service-contracts-counter.service';
import { IChecklist } from 'src/packages/checklistManagement/models/checklist';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { ChecklistsHelper } from '../../helpers/checklists-helper.service';
import { UserHelper } from '../../helpers/user-helper.service';
import { OfflineDataService } from '../../offlineData.service';
import { ObjectImageService } from '../system';
import { TemporaryImageService } from '../writeable-checklist/temporary-image.service';
import { IChecklistModule } from './../../../../packages/checklistManagement/models/checklist-module';

const secondsPerDay = 86400000;
type DateFilter = (obj: OrderModel) => boolean;

@Injectable({
  providedIn: 'root',
})
export class OrderService extends OfflineDataService<OrderModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    protected imageService: TemporaryImageService,
    private userHelper: UserHelper,
    private checklistHelper: ChecklistsHelper,
    private objectImageService: ObjectImageService,
    private counterService: ServiceContractsCounterService,
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Order',
      http,
      errorService,
      events,
      'orders/',
      'order',
      [
        'created_at',
        'updated_at',
        'created_by_id',
        'set_contact',
        'machines',
        'article',
        'priority',
        'office_user',
        'state',
        'customer',
        'timelogs',
        'article_name',
        'article_number',
        'assembly_address_id',
        'invoice_address_id',
        'unit_name',
        'remote_id',
        'machine',
        'attachments',
        'default_address',
        'order_field_users',
      ],
      [
        'positions',
        'writeable_checklists',
        'writeable_sanitation_checklists',
        'working_step_checklists',
        'position_disposals',
        'groups',
        'modules',
        'dep',
      ]
    );
  }

  /**
   * Validiert die Offlinedaten in jedem Sync
   * @return {boolean} true, wenn validiert werden soll
   */
  override get checkEachSync() : boolean {
    return this.checkAlreadyValidated();
  }

  /**
   * HTTP Options
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  /**
   * Ruft die möglichen Werte für die Filterung nach einem bestimmten Attribut ab
   * @param attribute Attribut, nach dem gefiltert werden soll
   * @returns string[] - Mögliche Werte für die Filterung
   */
  public filterValues(attribute: keyof OrderModel): Observable<string[]> {
    if(attribute === 'state') return of(['Offen', 'Abgeschlossen']);
    return of([]);
  }

  /**
   * Name des Icons für die Klasse
   */
  public get iconName(): string {
    return 'document-text-outline';
  }

  /**
   * Mache etwas mit dem Objekt nachdem es in die Queue eingefügt wurde beim Speichern
   *
   * Nachdem die Daten eingefügt wurden, müssen die Checklisten-PDFs zurückgesetzt werden,
   * sonst werden diese n mal hochgeladen.
   */
  protected afterQueued(object: OrderModel) {
    object.checklists = [];
    if (Array.isArray(object.assembly_reports)) {
      object.assembly_reports.forEach((report) => {
        report.checklists = [];
      });
    }
  }

  /**
   * Sucht Objekte mit Argumenten
   */
  public override where(args): Observable<ResponseModel<OrderModel>> {
    if (args) {
      const filter = this.transformArgs(args);

      return new Observable((subscriber) => {
        this.localWhere(args).then((result) => {
          result.data = result.data.filter(filter);
          subscriber.next(result);
          subscriber.complete();
        });
      });
    } else {
      return new Observable((subscriber) => {
        this.localAllwithPaging().then((result) => {
          subscriber.next(result);
          subscriber.complete();
        });
      });
    }
  }

  /**
   * Sucht Objekte mit Argumenten
   */
  public whereWithoutPaging(args = {}): Observable<OrderModel[]> {
    const filter = this.transformArgs(args);

    return new Observable((subscriber) => {
      this.localWhereWithoutPaging(args).then((result) => {
        subscriber.next(result.filter(filter));
        subscriber.complete();
      });
    });
  }

  saveRemote(incomingOrder: OrderModel): Observable<OrderModel> {
    const order: OrderModel = this.cloneObjekt(incomingOrder);
    let negativeOrderId = 0;
    if (order.id < 0) {
      negativeOrderId = order.id;
      delete order.id;
      delete order.invoice_address.id;
      delete order.assembly_address.id;
    }
    if (incomingOrder.report) order.report = incomingOrder.report;
    if (incomingOrder.checklists) order.checklists = incomingOrder.checklists;
    if (
      incomingOrder.assembly_reports &&
      incomingOrder.assembly_reports.length > 0
    ) {
      order.assembly_reports = incomingOrder.assembly_reports.map((ar) => {
        const newAR = Object.assign({}, ar);
        if (newAR.id < 0) delete newAR.id;
        if (ar.checklists) newAR.checklists = ar.checklists;
        if (ar.sanitation_checklists)
          newAR.sanitation_checklists = ar.sanitation_checklists;
        return newAR;
      });
    }

    this.checklistsCorrection(order);
    this.removeNotToUseParams(order);
    this.renameAttributedParams(order);

    if (order.id >= 0) {
      return this.sendUpdateRequest(order);
    } else {
      return this.http
        .post<OrderModel>(
          this.endpointWithUrl,
          this.formatPayload(order),
          this.httpOptions
        )
        .pipe(
          tap(async (res) => {
            if (negativeOrderId < 0) {
              await this.updateQueuedTimelogs(negativeOrderId, res);
              await this.localDelete(negativeOrderId);
            }
          })
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    }
  }

  /**
   * Löscht die Ids aus den Checklisten raus, falls sie aus dem Stamm kommen.
   * Dies ist eine Fallback Funktion
   * @param order
   */
  private checklistsCorrection(order: OrderModel) {
    try {
      this.checklistHelper.mapAndDeleteIds(
        order.writeable_checklists,
        'Order',
        order.id
      );

      order.assembly_reports.forEach((ar) => {
        this.checklistHelper.mapAndDeleteIds(
          ar.writeable_sanitation_checklists,
          'AssemblyReport',
          ar.id
        );
        this.checklistHelper.mapAndDeleteIds(
          ar.writeable_checklists,
          'AssemblyReport',
          ar.id
        );
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * Aktualisiert die enqueued Timelogs und macht sie zum versand bereit
   * Das muss gemacht werden, wenn die AuftragsId vorher negativ war.
   * @param negativeOrderId
   * @param res
   */
  private async updateQueuedTimelogs(negativeOrderId: number, res: OrderModel) {
    let dataQueued =
      (await this.queueService.getQueuedData()) as QueueEntry<any>[];

    const service = new SyncQueueService(this.indexedDBService, '');

    dataQueued = dataQueued
      .filter((q) => q.entityType === 'System::Timelog')
      .filter((q) => q.content.trackable_id === negativeOrderId);
    dataQueued.forEach((q) => {
      q.content.trackable_id = res.id;
      q.ready = true;

      service.update(q);
    });
  }

  /**
   * Update Request senden.
   * Der Update Request wird zwei geteilt, insofern Dateien angehangen sind
   * @private
   * @param {OrderModel} order
   */
  private sendUpdateRequest(order: OrderModel): Observable<OrderModel> {
    if (this.orderHasFilesOrImages(order)) {
      const observableFiles = this.updateRemoteFiles(order);
      const imageUpload = this.updateRemoteImages(order);

      return new Observable((observer) => {
        // Zunächst die Bilder von den Disposals hochladen
        imageUpload.then((res) => {
          // Dann die PDF-Berichte hochalden
          observableFiles.subscribe((resFiles) => {
            this.updateAssemlbyReportIds(order, resFiles);
            // Dann den Auftrag aktualisieren (assemblyReportIds)
            const observableMain = this.updateRemoteJson(order);
            observableMain.subscribe((resMain) => {
              observer.next(resMain);
              observer.complete();
            });
          });
        });
      });
    } else {
      return this.updateRemoteJson(order);
    }
  }

  /**
   * Zeigt an, ob der Auftrag Dateien oder Bilder enthält
   * - Prüft auf vorhandenen Report
   * - Prüft auf vorhandene Checklist-Dateien
   * - Prüft jeden AssemblyReport auf Checklist-Dateien
   * - Prüft jeden AssemblyReport auf Disposals mit Bildern
   * @param order
   * @private
   */
  private orderHasFilesOrImages(order: OrderModel): boolean {
    return (
        !!order.report ||
        !!order.checklists ||
        order.assembly_reports.some((ar) => !!ar.checklists) ||
        order.assembly_reports.some((ar) => !!ar.sanitation_checklists) ||
        order.assembly_reports.some((ar) => {
          return ar.position_disposals_attributes.some((pd) => !!pd.scrapping_image);
        })
    );
  }

  /**
   * Vom Request die AsemblyReport ID übernehmen für den nächsten
   * @param order
   * @param response
   */
  private updateAssemlbyReportIds(order: OrderModel, response: OrderModel) {
    order.assembly_reports
      .filter((ar) => !ar.id)
      .forEach((ar) => {
        const responseAr = response.assembly_reports.find(
          (newAR) => newAR.machine_id === ar.machine_id
        );
        if (responseAr) ar.id = responseAr.id;
      });
  }

  /**
   * Dateien getrennt hochladen
   * @param {OrderModel} order
   * @return {Observable<OrderModel> }
   */
  private updateRemoteFiles(order: OrderModel): Observable<OrderModel> {
    const orderToSend = this.buildFileOrder(order);

    return this.http
      .put<OrderModel>(
        this.endpointWithUrl + order.id,
        toFormData(orderToSend, this, [
          'checklists',
          'report',
          'sanitation_checklists',
        ])
      )
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Bilder versenden aus den lokalen Daten
   * @param order
   */
  private async updateRemoteImages(order: any): Promise<any> {
    const mapper = (obj) => obj[0].dataUrl;
    await this.objectImageService.sendImages(
      order.position_disposals_attributes,
      'scrapping_image',
      'scrapping_image_remote_id',
      mapper
    );
    for (const assemblyReport of order.assembly_reports) {
      await this.objectImageService.sendImages(
        assemblyReport.position_disposals_attributes,
        'scrapping_image',
        'scrapping_image_remote_id',
        mapper
      );
    }
  }

  /**
   * Aktualisiert den Auftrag als JSON-Objekt
   * @param {OrderModel} order
   * @return {Observable<OrderModel>}
   */
  private updateRemoteJson(order: OrderModel): Observable<OrderModel> {
    // Entferne Dateien da nicht notwendig
    delete order.report;
    delete order.checklists;

    // Entferne Adressen des Auftrags da nicht notwendig
    delete order.assembly_address;
    delete order.invoice_address;

    return this.http
      .put<OrderModel>(
        this.endpointWithUrl + order.id,
        this.formatPayload(order),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Minimales Objekt zum versenden von Anhängen
   * @param {OrderModel} order
   * @return {OrderModel}
   */
  private buildFileOrder(order: OrderModel): OrderModel {
    return {
      id: order.id,
      report: order.report,
      checklists: order.checklists,
      assembly_reports: order.assembly_reports.map((ar) => {
        return {
          id: ar.id,
          machine_id: ar.machine_id,
          checklists: ar.checklists,
          sanitation_checklists: ar.sanitation_checklists,
        };
      }),
    } as OrderModel;
  }

  /**
   * Bilder aus Checklisten an den Hintergrundversand übergeben
   */
  public async sendChecklistImages(order: OrderModel) {
    const checklists = order.writeable_checklists;
    for (const checklist of checklists) {
      await this.sendForChecklist(checklist);
    }
  }



  /**
   * Filtert die Aufträge nachdem aktuellen User und
   * führt eine Suche per Where aus
   */
  public forUser(args: Record<string, any> = {}): Observable<OrderModel[]> {
    if (!args) args = {};
    const userId = this.userHelper.getUser()?.id;
    args.order_field_users = [{ user_id: userId }];
    return this.whereWithoutPaging(args);
  }


  /**
   * Versand einer Checkliste. Iteriert über die Module um diese zu versenden
   */
  private sendForChecklist(checklist: IChecklist): Promise<any> {
    const promises = [];

    checklist.groups.forEach((group) => {
      group.modules.forEach((module) => {
        promises.push(this.sendForModule(module));
      });
    });

    return Promise.all(promises);
  }

  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des OfflineDataService

  /**
   * Gibt mit Löschen in der Lokalen Datenbank die id des zu löschenden Objekts ebenfalls an den ServiceContractsCounterService
   * weiter, in dem die Serviceaufträge in Memory gezählt werden
   * @param id
   */
  public override async localDelete(id: number): Promise<boolean> {
    this.counterService.onLocalDelete(id);
    return super.localDelete(id);
  }


  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des SyncService

  /**
   * Gibt nach dem Speichern in der Lokalen Datenbank das Objekt an den ServiceContractsCounterService weiter,
   * in dem die Service-Aufträge in Memory gezählt werden
   * @param obj - die Entität, die gespeichert wird
   * @param updatedAt - das Änderungsdatum
   * @protected
   * @return Promise<boolean | StoredData<OrderModel>> - das gespeicherte Objekt
   */
  protected override async store(obj: OrderModel, updatedAt: string): Promise<boolean | StoredData<OrderModel>> {
    const storedObj: boolean | StoredData<OrderModel> | void = await super.store(obj, updatedAt);
    if (storedObj && (storedObj as StoredData<OrderModel>)?.content) {
      this.counterService.onStore((storedObj as StoredData<OrderModel>).content);
    }
    return storedObj;
  }

  /**
   * Sendet alle Bilder für ein Module und setzt die temporary_image_remote_ids, so dass
   * später eine Zuordnung stattfinden kann
   */
  private async sendForModule(module: IChecklistModule): Promise<any> {
    const promises = [];

    // Eigenes Module versenden
    if (module.type === 'ChecklistManagement::Modules::GalleryModule') {
      const remoteIds: string[] = await this.imageService.sendImages(module.id);
      module.temporary_image_remote_ids = remoteIds;
    }

    // Dependencies abarbeiten
    module.dep.forEach((dep) => {
      dep.modules.forEach((subModule) => {
        promises.push(this.sendForModule(subModule));
      });
    });

    return Promise.all(promises);
  }

  /**
   * Transformiert die Argumente für die Suche eines Auftrages
   */
  private transformArgs(args: Record<any, any>): DateFilter {
    let filter: DateFilter = (obj) => true;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today.getTime() + 1 * secondsPerDay);
    const dateBefore7Days = new Date(today.getTime() - 7 * secondsPerDay);

    switch (args.orders_filter) {
      case 'open':
        args.state_name = [
          'Offen',
          'Übermittelt',
          'Anfahrt',
          'In Bearbeitung',
          'Pause',
          'Unterschrift / Vorbereitung Abreise',
        ];
        filter = (obj: OrderModel) =>
          new Date(obj.execution_date) < tomorrow &&
          new Date(obj.execution_date) >= today;
        break;
      case 'future':
        args.state_name = ['Offen'];
        filter = (obj: OrderModel) => new Date(obj.execution_date) >= tomorrow;
        break;
      case 'last':
        args.state_name = ['Abgeschlossen', 'Auftrag abgebrochen'];
        filter = (obj: OrderModel) =>
          new Date(obj.created_at) > dateBefore7Days;
        break;
      case 'active':
        args.state_name = [
          'Anfahrt',
          'In Bearbeitung',
          'Pause',
          'Unterschrift / Vorbereitung Abreise',
        ];
        filter = (obj: OrderModel) => new Date(obj.execution_date) < tomorrow;
        break;

      default:
        break;
    }

    delete args.orders_filter;

    return filter;
  }
}
