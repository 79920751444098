import { Directive, Input, OnInit, OnChanges, OnDestroy, ElementRef, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: 'div[mitsHtmlRenderer]',
    standalone: true
})
export class MITSHtmlRendererDirective implements OnInit, OnChanges, OnDestroy {
    @Input() mitsHtmlRenderer!: (value: any) => HTMLElement;
    @Input() value: any;

    private element: HTMLElement | null = null;

    constructor(private elRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.renderElement();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value || changes.oscHtmlRenderer) {
            this.renderElement();
        }
    }

    ngOnDestroy() {
        if (this.element) {
            this.renderer.removeChild(this.elRef.nativeElement, this.element);
        }
    }

    private renderElement() {
        if (this.element) {
            this.renderer.removeChild(this.elRef.nativeElement, this.element);
        }
        if (this.mitsHtmlRenderer) {
            this.element = this.mitsHtmlRenderer(this.value);
            if (this.element) {
                this.renderer.appendChild(this.elRef.nativeElement, this.element);
            }
        }
    }
}