<mits-header i18n-title title="Auftrag erstellen"></mits-header>
<ion-content>
    <form *ngIf="_FORM"
          [formGroup]="_FORM">
        <ion-list inset
                  lines="none"
                  class="mits-form">
            <mits-form-header i18n-title title="Kundendaten"
                              i18n-description description="Kunden, Ansprechparter und Standort auswählen.">
            </mits-form-header>
            <mits-form-object-selection i18n-label label="Kunde"
                                        formControlName="customer"
                                        (changedSelection)="customerSelectionChanged($event)"
                                        [dataFunction]="customersDataFunction"
                                        [loadingType]="'infinite-scroll'"
                                        [returnObjectsInsteadOfIds]="true"
                                        [titleFields]="['name1', 'name2']"
                                        [subtitleFields]="['snc_number']">
            </mits-form-object-selection>
            <mits-form-object-selection *ngIf="selectableCustomerContacts.length > 0"
                                        i18n-label label="Ansprechpartner"
                                        formControlName="customer_contact_id"
                                        [data]="selectableCustomerContacts"
                                        [titleFields]="['firstname', 'lastname']">
            </mits-form-object-selection>
            <mits-form-object-selection *ngIf="selectableCustomerContacts.length > 0"
                                        label="Standort"
                                        i18n-label
                                        formControlName="location"
                                        (changedSelection)="resetOrderFormOnLocationChange()"
                                        [returnObjectsInsteadOfIds]="true"
                                        [data]="selectableCustomerLocations"
                                        [titleFields]="['location_number', 'name1', 'name2', 'floor', 'floor_position']"
                                        [subtitleFields]="['typeDisplay']"
            >
            </mits-form-object-selection>
        </ion-list>
        <ion-list *ngIf="_FORM.value.customer?.id"
                  inset
                  lines="none"
                  class="mits-form">
            <mits-form-header i18n-title title="Auftragsdaten"
                              i18n-description description="Details zum Auftrag auswählen.">
            </mits-form-header>
            <mits-form-checkbox i18n-label label="Befüllerauftrags-Layout?"
                                formControlName="filler_order">
            </mits-form-checkbox>
            <mits-form-object-selection *ngIf="_FS.checklistOnOrder"
                                        i18n-label label="Checklisten"
                                        formControlName="checklists"
                                        [multiple]="true"
                                        [disableSearch]="true"
                                        [returnObjectsInsteadOfIds]="true"
                                        [dataFunction]="checklistsDataFunction"
                                        [titleFields]="['name']">
            </mits-form-object-selection>
            <mits-boilerplates placeholder="Auftragsbeschreibung / Tätigkeit..."
                               i18n-placeholder
                               formControlName="description"
                               useBoilerplates="ausstehende Arbeiten"
                               class="mits-form-item">
            </mits-boilerplates>
        </ion-list>
        <ion-list *ngIf="_FORM.value.customer?.id && _FORM.value.location?.id"
                  inset
                  lines="none"
                  formControlStatus="assembly_reports"
                  class="mits-form">
            <mits-form-header i18n-title title="Maschinen"
                              [showAdd]="!_FS.canOnlyAddSingleMachine || _FORM.value.machines?.length === 0"
                              addTriggerID="addMachineButton"
                              i18n-description description="Maschinen für den Auftrag auswählen.">
            </mits-form-header>
            <mits-form-object-selection i18n-label label="Maschine"
                                        [dataFunction]="machinesDataFunction"
                                        [hideInputField]="true"
                                        [multiple]="!_FS.canOnlyAddSingleMachine"
                                        externalTrigger="addMachineButton"
                                        [enableScanner]="true"
                                        (changedSelection)="machineSelectionChanged($event)"
                                        [returnObjectsInsteadOfIds]="true"
                                        [reinitOnNextModalOpen]="reinitMachineSelection"
                                        [titleFields]="machineSearchableTitleFields"
                                        [subtitleFields]="machineSearchableSubtitleFields">
            </mits-form-object-selection>
            <mits-data-grid *ngIf="_FORM.value.machines?.length > 0"
                            [data]="_FORM.value.machines"
                            [columnConfigs]="selectedMachinesColumnsConfig"
                            [itemsClickable]="false">,
            </mits-data-grid>
        </ion-list>
    </form>
</ion-content>

<mits-footer *ngIf="_FORM"
             [disabled]="_FORM?.invalid"
             i18n-buttonText
             buttonText="Auftrag erstellen"
             (buttonClicked)="save()">
</mits-footer>

