import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ArticleService } from 'src/app/providers/model-services/article.service';
import { UserService } from 'src/app/providers/model-services/user.service';
import {
  BasicInventoryModel,
  BasicPositionModel,
} from 'src/packages/mitsBasics/interfaces';
import { CarInventoryArticleModel, CarInventoryMovementsModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CarInventoryMapper {
  constructor(
    private readonly articleService: ArticleService,
    private readonly userService: UserService
  ) {}

  async mapToBasicInventory(
    inventory: CarInventoryMovementsModel
  ): Promise<BasicInventoryModel> {
    const currentUser = await firstValueFrom(this.userService.current_user());
    return {
      positions: await Promise.all(
        inventory.inventory_articles.map((position) =>
          this.mapToBasicPosition(position)
        )
      ),
      disposals: await Promise.all(
        inventory.disposal_articles.map((position) =>
          this.mapToBasicPosition(position)
        )
      ),
      created_by_id: currentUser.id,
      stock_object_id: null,
      inventory_booking_type: inventory.inventory_booking_type,
    };
  }

  async mapToCarInventory(
    inventory: BasicInventoryModel
  ): Promise<CarInventoryMovementsModel> {
    return {
      remote_id: null,
      name: '',
      disposal_articles: await Promise.all(
        inventory.disposals.map((position) => this.mapToCarPosition(position))
      ),
      inventory_articles: await Promise.all(
        inventory.positions.map((position) => this.mapToCarPosition(position))
      ),
      description: '',
      inventory_booking_type: inventory.inventory_booking_type,
    };
  }

  async mapToBasicPosition(
    position: CarInventoryArticleModel
  ): Promise<BasicPositionModel> {
    const article = await firstValueFrom(
      this.articleService.find(position.article_id)
    );
    return {
      id: position.id,
      created_at: position.created_at,
      updated_at: position.updated_at,
      amount: position.amount,
      amount_cartonage: Math.floor(
        position.amount / (article?.overpack_size || 0)
      ),
      amount_single: position.amount % (article?.overpack_size || 0),
      article_id: article?.id || position.article_id,
      article: article,
      article_name: article?.name || position.article_name,
      article_number: article?.article_number || position.article_number,
    };
  }

  // Tour 03 inventar artikel nicht mehr da
  // Tour 22 inventar artikel nicht mehr da

  async mapToCarPosition(
    position: BasicPositionModel
  ): Promise<CarInventoryArticleModel> {
    return {
      article_id: position.article_id,
      article_name: position.article.name,
      article_number: position.article.article_number,
      amount: position.amount,
      car_id: null,
      user_id: null,
      client_ok: false,
      text: '',
      overpack_size: position.article.overpack_size,
      overpack_unit: position.article.packaging_unit?.name,
      created_time: position.created_at,
      object_reference: null,
    };
  }
}
