import { inject } from '@angular/core';
import { FunctionSwitchHelperService } from 'src/app/providers/component-helpers/function-switch.service';

/**
 * Generischer Typ, der die Konfiguration der Funktionsschalter in die entsprechenden TypeScript-Typen abbildet.
 */
export type FunctionSwitchesFromConfig<T> = {
  [K in keyof T]: boolean;
};

/**
 * Lädt die Funktionsschalter anhand der übergebenen Konfiguration.
 * @param config - Konfiguration der Funktionsschalter.
 * @returns Ein Objekt, das die geladenen Funktionsschalter als Boolean-Werte enthält.
 */
export const getFunctionSwitches = <T extends Record<string, string>>(config: T): FunctionSwitchesFromConfig<T> => {
  return inject(FunctionSwitchHelperService).load(config) as FunctionSwitchesFromConfig<T>;
};