import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImageDrawingModule } from 'ngx-image-drawing';
import { MitsObjectSelectModule } from './../mits-object-select/mits-object-select.module';
import { MitsBoilerplatesComponent } from './mits-boilerplates.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ImageDrawingModule,
    MitsObjectSelectModule,
  ],
  declarations: [MitsBoilerplatesComponent],
  entryComponents: [MitsBoilerplatesComponent],
  exports: [MitsBoilerplatesComponent],
  providers: [],
})
export class MitsBoilerplatesModule {}
