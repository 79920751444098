import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IonicModule} from '@ionic/angular';

@Component({
    selector: 'mits-form-header',
    templateUrl: './mits-form-header.component.html',
    styleUrls: ['./mits-form-header.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
    ]
})
export class MitsFormHeaderComponent {
    // Der Titel der Angezeigt werden soll
    @Input() title?: string | undefined;
    // Ob ein Add-Button angezeigt werden soll
    @Input() showAdd = false;
    // Die ID des Add-Buttons, um als Trigger verwendet zu werden
    @Input() addTriggerID?: string;
    // Event, das ausgelöst wird, wenn der Add-Button geklickt wird
    @Output() addClicked: EventEmitter<void> = new EventEmitter<void>();
    // Der Text, der als Beschreibung angezeigt werden soll
    @Input() description?: string | undefined;
}
