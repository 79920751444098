import {Routes} from '@angular/router';

// Die Parent-Route für die Inventuren
export const INVENTORIES_BASE_ROUTE = 'inventories';
// Die Child-Routen für die Inventuren
export const INVENTORIES_ROUTES: Routes = [
    {
        path: '',
        loadComponent: () => import('./ui/pages/inventories/inventories.page.component')
            .then(c => c.InventoriesPage)
    },
    {
        path: 'details/warehouse/:id',
        loadComponent: () => import('./ui/pages/inventory-details/warehouse/warehouse-inventory-details.page.component')
            .then(c => c.WarehouseInventoryDetailsPage)
    },
    {
        path: 'details/car/:id',
        loadComponent: () => import('./ui/pages/inventory-details/car/car-inventory-details.page.component')
            .then(c => c.CarInventoryDetailsPage)
    },
];
