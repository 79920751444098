import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {IonicModule} from '@ionic/angular';

/**
 * Komponente, welche die volle Fläche der Elternkomponente verdeckt und einen Loading-Spinner anzeigt
 * - Optionaler Text, der unter dem Spinner angezeigt wird
 */
@Component({
  selector: 'mits-loading-spinner',
  templateUrl: './mits-loading-spinner.component.html',
  styleUrls: ['./mits-loading-spinner.component.scss'],
  standalone: true,
  imports: [
      CommonModule,
      IonicModule
  ]
})
export class LoadingSpinnerComponent {
  // Optionaler Text, der unter dem Spinner angezeigt wird
  @Input() text = '';
}
