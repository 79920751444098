import {Directive, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MitsFormCheckboxComponent} from '../mits-form-checkbox.component';

/**
 * Direktive, zur Nutzung der MitsFormCheckboxComponent in Angular Reactive Forms
 */
@Directive({
  selector: 'mits-form-checkbox[formControlName]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MitsFormCheckboxControlValueAccessorDirective),
      multi: true
    }
  ],
  standalone: true
})
export class MitsFormCheckboxControlValueAccessorDirective implements ControlValueAccessor, OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private host: MitsFormCheckboxComponent) {
  }

  /**
   * Initialisiert die Direktive
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.host.checkedChage.subscribe((value: boolean) => {
          this.onTouched();
          this.onChange(value);
        }
      )
    );
  }

  /**
   * Wird aufgerufen, wenn die Direktive zerstört wird
   */
  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  /**
   * Wird von FormControl aufgerufen, um den Wert zu setzen
   * @param value - Wert, der gesetzt werden soll
   */
  writeValue(value: boolean): void {
    this.host.checked = value;
  }

  /**
   * Registriert die Callback-Funktion, die aufgerufen wird, wenn sich der Wert in der Komponente ändert
   * @param fn - Callback-Funktion der Parent-Komponente
   */
  registerOnChange(fn: never): void {
    this.onChange = fn;
  }

  /**
   * Registriert die Callback-Funktion, die aufgerufen wird, wenn der Nutzer das Formularfeld "berührt"
   * @param fn - Callback-Funktion der Parent-Komponente
   */
  registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }

  /**
   * Wird von FormControl aufgerufen, um das Formularfeld zu aktivieren/deaktivieren
   * @param isDisabled - Boolean, der angibt, ob das Formularfeld deaktiviert werden soll
   */
  setDisabledState?(isDisabled: boolean): void {
    this.host.disabled = isDisabled;
  }

  /**
   * Callback-Funktion, die aufgerufen wird, wenn sich der Wert in der Komponente ändert
   * @param _ - Das neue Datum
   */
    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  private onChange = (_: boolean): void => {
    console.log('onChange', _);
  };

  /**
   * Callback-Funktion, die aufgerufen wird, wenn der Nutzer das Formularfeld "berührt"
   */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = (): void => {
  };
}
