import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Html5QrcodeScanType } from 'html5-qrcode/esm/core';
import { Html5Qrcode } from 'html5-qrcode/esm/html5-qrcode';
import { ONLY_NATIVE_SCANNER } from 'src/assets/constants/functionSwitch.constants';
import { FunctionSwitchHelperService } from '../../../providers/component-helpers/function-switch.service';
import {BooleanCameraCapability} from 'html5-qrcode/camera/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'app-mits-scanner-modal',
  templateUrl: './mits-scanner-modal.page.html',
  styleUrls: ['./mits-scanner-modal.page.scss'],
})
// * Modal for scanner itself */
export class MitsScannerModalPage {
  /** FLAG: Can only Scan with native scanner? (scans text and pastes it in input) */
  onlyUseNative: boolean;
  /** FLAG: Is the modal ready for the native scanner to scan */
  nativeScanState = 0;
  /** Var for intitializing a scanner device */
  html5QrCode: Html5Qrcode;
  /** FLAG: Is the access to the camera denied */
  accessDenied = false;
  /** Data scanned by native scanner */
  scannerData: string;
  // BehaviorSubject zur Anzeige, ob die Taschenlampenfunktion unterstützt wird
  private torchSupportedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public torchSupported$: Observable<boolean> = this.torchSupportedSubject.asObservable();
  // Zustand der Taschenlampe
  private isTorchOn = false;

  /** Element of the Backdrop */
  @ViewChild('scannerWrapper')
  scannerWrapperElement: ElementRef<HTMLSpanElement>;
  /** Element of the Input */
  @ViewChild('scannerNativeInput')
  scannerNativeInputElement: ElementRef<HTMLInputElement>;

  constructor(
    private readonly functionSwitchHelper: FunctionSwitchHelperService,
    private readonly modalCtrl: ModalController
  ) {
    this.onlyUseNative = this.functionSwitchHelper.has(ONLY_NATIVE_SCANNER);
  }

  ionViewDidEnter() {
    if (this.onlyUseNative) {
      this.initNative();
    } else {
      this.initScanner();
    }
  }

  /**
   * Initializes Page to support native scanner
   */
  initNative() {
    this.nativeScanState = 0;
    this.scannerData = '';
    this.scannerNativeInputElement.nativeElement.focus();
    this.nativeScanState = 1;
  }

  /**
   * Initializes the scanner
   */
  initScanner() {
    this.html5QrCode = new Html5Qrcode('reader');

    /** Handles the scanned data if the scan was successful */
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      this.html5QrCode
        .stop()
        .then(() => {
          this.modalCtrl.dismiss({ decodedResult });
        })
        .catch((err) => {
          console.error(err);
        });
    };

    /** Configures the options for scanning */
    const config = {
      fps: 10,
      qrbox: { width: 350, height: 250 },
      aspectRatio:
        Math.ceil(
          (this.scannerWrapperElement.nativeElement.offsetHeight /
            this.scannerWrapperElement.nativeElement.offsetWidth) *
            100
        ) / 100,
      rememberLastUsedCamera: true,
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
    };

    /** Gets all available devices and chooses the first one for the scan */
    Html5Qrcode.getCameras()
      .then((devices) => {
        if (devices && devices.length > 0) {
          this.html5QrCode.start(
            { facingMode: 'environment' },
            config,
            qrCodeSuccessCallback,
            () => {}
          );
          if (this.html5QrCode?.getRunningTrackCameraCapabilities()?.torchFeature()?.isSupported()) {
            this.torchSupportedSubject.next(true);
          }
        }
      })
      /** Timeout is needed because to open and to close a modal within a few milliseconds causes an error */
      .catch((error) => {
        console.error('Error while starting QR-Code Scanner', error);
      });
  }

  /**
   * Event-Handler zur Verarbeitung des gescannten Textes durch den nativen Scanner
   * @param ev - Gescannter Text
   */
  changedScannerData(ev: string): void {
    this.scannerData = ev;
    if (this.scannerData?.length > 0) {
      this.nativeScanState = 2;
      this.modalCtrl?.getTop()
        .then(async (overlay: HTMLIonModalElement): Promise<void> => {
          await overlay?.dismiss({
            decodedResult: {
              decodedText: this.scannerData,
            },
          });
        })
        .catch((err) => console.error(err));
    } else {
      this.nativeScanState = 1;
    }
  }

  /**
   * Closes the modal
   */
  closeModal() {
    if (
      this.accessDenied ||
      this.onlyUseNative ||
      this.html5QrCode.getState() === 1
    )
      this.modalCtrl.dismiss();
    else
      this.html5QrCode.stop().then(() => {
        this.modalCtrl.dismiss();
      });
  }
  /**
   * Methode zum Umschalten der Taschenlampe
   */
  public toggleTorch(): void {
    if (this.html5QrCode) {
      const torch: BooleanCameraCapability = this.html5QrCode.getRunningTrackCameraCapabilities()?.torchFeature();
      if (torch?.isSupported()) {
        const newTorchState = !this.isTorchOn;
        torch.apply(newTorchState)
            .then(() => {
              this.isTorchOn = newTorchState;
            })
            .catch(error => {
              console.error('[MitsScannerService][toggleTorch()] Fehler beim Umschalten der Taschenlampe', error);
            });
      }
    }
  }
}
