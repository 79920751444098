import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ArticleModel } from 'src/app/models/articles/article';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';
import {
  BasicInventoryModel,
  BasicPositionModel,
  IInventoryHelper,
  INVENTORY_HELPER_TYPE,
} from 'src/packages/mitsBasics/interfaces';
import {
  CarInventoryArticleModel,
  CarInventoryMovementsModel,
  CarModel,
} from '../models';
import { CarInventoryMovementsService } from '../providers';
import { CarInventoryMapper } from './car-inventory-mapper.service';
import {CarInventoryInitRequestModel, CarInventoryService} from '../providers';
import {CarInventoryModel} from '../../../app/pages/backend/inventories/models/car/car.inventory.model';

export type InventoryType = 'inventory_articles' | 'disposal_articles';

const LOCALSTROAGE_KEY = 'CAR_STORAGE';

@Injectable({
  providedIn: 'root',
})
export class CarInventoryHelper implements IInventoryHelper {
  car: CarModel;
  userId: number;

  constructor(
    private readonly carInventoryMapper: CarInventoryMapper,
    private readonly carInventoryService: CarInventoryMovementsService,
    private readonly carInventoryInitService: CarInventoryService
  ) {}

  startInventur(): Promise<boolean | void> {
    return Promise.resolve();
  }

  /**
   * Typen des Helpers laden
   * @return {INVENTORY_HELPER_TYPE}
   */
  getType(): INVENTORY_HELPER_TYPE {
    return 'car';
  }

  /**
   * Locales Inventory vorhanden?
   */
  public hasLocalInventory(): boolean {
    return !!this.localInventory;
  }

  /**
   * Wird hier nicht benötigt
   * @param sourceId
   * @param targetId
   */
  async setSourceAndTarget(sourceId: number, targetId: number) {
    console.debug(
      'CarInventoryHelper: setSourceAndTarget (nothing to do)',
      sourceId,
      targetId
    );
  }

  /**
   * Remote Inventory laden
   */
  private getRemoteInventory(): Observable<CarInventoryMovementsModel> {
    return this.carInventoryService.getInventory();
  }

  /**
   * Überprüft ob ein Artikel geladen ist
   * @param {ArticleModel} article
   * @return {boolean}
   */
  public isLoaded(article: ArticleModel): boolean {
    const articleInv = this.localInventory.inventory_articles.filter(
      (i) => i.article_id === article.id
    )[0];

    if (articleInv) {
      return articleInv.amount > 0;
    } else {
      return false;
    }
  }

  /**
   * Gibt die Menge eines Artikles zurück im Inventar des Autos
   */
  public getCurrentAmount(articleId: number): number {
    const inventory = this.localInventory;
    const articleInv = inventory?.inventory_articles?.find(
      (i) => i.article_id === articleId
    );
    return articleInv ? articleInv.amount : 0;
  }

  /**
   * Bulk Inventarverbuchung für eine Combined Postion
   */
  public async updateInventoryBulk(
    articleId: number,
    amount: number,
    takeBack: number,
    disposalAmount: number
  ) {
    // Normale Bestandsveränderung und Rücknahmen auf Lager in einer Buchung
    await this.updateInventory(articleId, amount - takeBack);
    // Schrott aufs Lager buchen
    this.updateDisposal(articleId, +disposalAmount);
  }

  /**
   * Verbuchungen vom normalen Lagerbestand
   */
  public async updateInventory(articleId: number, amount: number) {
    this.updateInventoryInternal(articleId, amount, 'inventory_articles');
    return true;
  }

  /**
   * Erstellt eine Inventur
   * @param invBasic welche gespeichert wedren soll
   */
  public async endInventur(
    invBasic: BasicInventoryModel
  ): Promise<boolean | void> {
    if (!invBasic) return;
    const inventoryMovements: CarInventoryMovementsModel = await this.carInventoryMapper.mapToCarInventory(invBasic);
    inventoryMovements.remote_id = this.car.remote_id;
    inventoryMovements.disposal_articles = this.mapArticles(inventoryMovements.disposal_articles);
    inventoryMovements.inventory_articles = this.mapArticles(
      inventoryMovements.inventory_articles
    );
    let error = false;
    // Modell zur Initialisierung der Inventur
    const carInventoryInitRequest: CarInventoryInitRequestModel = {
        car_id: this.car.id,
        user_id: this.userId,
        created_time: inventoryMovements.inventory_articles.some((i) => i.created_time)
            ? inventoryMovements.inventory_articles[0].created_time
            : new Date(),
    }
    // Backend-Seitig initialisierte Inventur
    const carInventory: CarInventoryModel = await firstValueFrom(this.carInventoryInitService.newInventory(carInventoryInitRequest));
    // Inventurbuchungen mit der ID der Backend-Seitig initialisierten Inventur verknüpfen
    inventoryMovements?.inventory_articles?.forEach((i) => {
        i.car_inventory_id = carInventory.id;
    });
    inventoryMovements?.disposal_articles?.forEach((i) => {
        i.car_inventory_id = carInventory.id;
    });
    // Inventurbuchungen speichern
    await firstValueFrom(this.carInventoryService.save(inventoryMovements)).catch(
      (err) => {
        error = true;
        console.error(err);
      }
    );
    await this.updateLocalInventory();
    return !error;
  }

  private mapArticles(
    articles: CarInventoryArticleModel[]
  ): CarInventoryArticleModel[] {
    const datetime = new Date();
    return articles.map((article) => {
      article.created_time = datetime || new Date();
      article.user_id = this.userId;
      article.car_id = this.car.id;
      return article;
    });
  }

  /**
   * Verbuchungen von Entsorgungen
   */
  private updateDisposal(articleId: number, amount: number) {
    this.updateInventoryInternal(articleId, amount, 'disposal_articles');
  }

  /**
   * Lokales Repository aktualisieren
   */
  public async updateLocalInventory(force: boolean = false) {
    const inventory = await firstValueFrom(this.getRemoteInventory());

    if (this.localInventory && inventory) {
      if (
        force ||
        GlobalHelper.dateGreaterEquals(
          inventory.updated_at as string,
          this.localInventory.updated_at as string
        )
      ) {
        this.localInventory = inventory;
      } else {
        console.warn(
          'Lokales Inventar nicht geupdated, da lokaler Zeitstempel neuer'
        );
      }
    } else {
      this.localInventory = inventory;
    }
  }

  /**
   * Positionen zurückgeben welche durch die gegebene Inventur auf 0 gesetzt werden
   * @returns Positionen welche auf 0 gesetzt werden
   */
  public async getZeroAmountPositions(
    inventory: BasicInventoryModel
  ): Promise<BasicPositionModel[]> {
    const stock = await firstValueFrom(this.getRemoteInventory());
    const carInventory = await this.carInventoryMapper.mapToCarInventory(
      inventory
    );
    const filteredPositions = stock.inventory_articles.filter(
      (currPosition) => {
        const pos = carInventory.inventory_articles.find(
          (invPosition) => invPosition.article_id === currPosition.article_id
        );
        return !pos || pos.amount === 0;
      }
    );
    return Promise.all(
      filteredPositions.map((fp) =>
        this.carInventoryMapper.mapToBasicPosition(fp)
      )
    );
  }

  /**
   * Getter Locales Inventory
   */
  private get localInventory(): CarInventoryMovementsModel {
    return JSON.parse(localStorage.getItem(LOCALSTROAGE_KEY));
  }

  /**
   * Setter Locales Inventory
   */
  private set localInventory(v: CarInventoryMovementsModel) {
    localStorage.setItem(LOCALSTROAGE_KEY, JSON.stringify(v));
  }

  /**
   * Lokales Fahrzeuglager aktualisieren
   */
  private updateInventoryInternal(
    articleId: number,
    amount: number,
    type: InventoryType
  ) {
    const inventory = this.localInventory;
    const position = inventory[type].find((i) => i.article_id === articleId);
    if (position) {
      position.amount -= amount;
    } else {
      inventory[type].push({ article_id: articleId, amount: amount * -1 });
    }
    inventory.updated_at = new Date().toISOString();
    this.localInventory = inventory;
  }
}
