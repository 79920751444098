import { Pipe, PipeTransform } from '@angular/core';
import {Identifiable} from '../../../../models/basic';

/**
 * Pipe, um ein Objekt anhand von bestimmten Feldern darzustellen
 */
@Pipe({
  name: 'ObjectSelectionDisplayPipe',
  standalone: true
})
export class ObjectSelectionDisplayPipePipe<T extends Identifiable> implements PipeTransform {
  transform(value: T | T[] | undefined, fields: string[] | undefined): string {
    if (!value || !fields) {
      return '';
    }
    if (Array.isArray(value)) {
      return value.map(item => fields
          .map(field => item[field as keyof T] || '')
          .filter(Boolean)
          .join(', ')
      ).join('; ');
    } else {
      return fields
          .map(field => value[field as keyof T] || '')
          .filter(Boolean)
          .join(', ');
    }
  }
}
