<mits-header [title]="objectTypeTitle + ' ' + modalTitleAttachment" [modal]="true"></mits-header>

<ion-content>
  <div class="mitsObjectSelectHeader">
    <ng-container *ngIf="headerTemplate">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </ng-container>
    <span class="mitsObjectSelectToolbar" *ngIf="displayObjects">
      <mits-scanner-button [size]="26" [width]="42" (scannerData)="scannedData($event)"></mits-scanner-button>
      <span class="mitsObjectSelectButton">
        <mits-icon name="search"></mits-icon>
      </span>
      <input #mitsObjSelSearchInput type="search" [formControl]="searchControl"
        class="mitsObjectSelectSearchInput" [placeholder]="searchPlaceholderText">
      <button *ngIf="showClearInputButton" class="clear-input-button" aria-label="Clear input" title="Clear input"
        (click)="handleButtonClick()">×</button>
    </span>
  </div>
  <ion-list *ngIf="displayObjects">
    <div class="infoItem" *ngIf="fulltextRunning">
      <span i18n>Suchindex nicht aufgebaut</span>
      <p i18n>
        Die Suche funktioniert nur mit Einschränkungen, da der Suchindex nicht vollständig aufgebaut ist.
      </p>
    </div>
    <ng-container *ngFor="let obj of displayObjects; let index = index">
      <ion-item *ngIf="!obj.hidden">
        <ion-checkbox slot="start" [ngModel]="obj.selected" (ngModelChange)="selected(index, $event)"
          [disabled]="isDisabledObject(obj.object?.id) || (disableSelectedElements && obj.selected && !enableClickDisabledElement)"></ion-checkbox>
        <ion-label [ngClass]="{'disabled': disableSelectedElements && obj.selected && !enableClickDisabledElement}">
          <div class="slotBox startSlot" *ngIf="obj.displayTexts.startSlot && obj.displayTexts.startSlot[0]">
            <span>{{obj.displayTexts.startSlot[0]}}</span>
          </div>
          <div class="mainSlot">
            <h5 *ngIf="obj.displayTexts.title">
              <span *ngFor="let display of obj.displayTexts.title; let last = last">
                {{display}}<span *ngIf="!last">, </span>
              </span>
            </h5>
            <h6 *ngIf="obj.displayTexts.subTitle">
              <ng-container *ngIf="!seperateLinesForSubtitle; else separateLinesSubTitle">
                  <span *ngFor="let display of obj.displayTexts.subTitle; let last = last">
                    {{ display }}<span *ngIf="!last">, </span>
                  </span>
              </ng-container>
              <ng-template #separateLinesSubTitle>
                <div *ngFor="let display of obj.displayTexts.subTitle">{{ display }}</div>
              </ng-template>
            </h6>
          </div>
        </ion-label>
        <div slot="end" class="slotBox" *ngIf="obj.displayTexts.endSlot && obj.displayTexts.endSlot[0]">
          <span>{{obj.displayTexts.endSlot[0]}}</span>
        </div>
      </ion-item>
    </ng-container>
  </ion-list>
  <ion-infinite-scroll *ngIf="infinityLoadingService" (ionInfinite)="onIonInfinite($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<ion-footer>
  <ng-content select="footer"></ng-content>
  <ion-row>
    <ion-col>
      <ion-button expand="block" color="danger" (click)="close()" (keyup)="close()">
        {{modalBackButtonText}}
      </ion-button>
    </ion-col>
    <ion-col *ngIf="!enableCloseAfterSelect">
      <ion-button expand="block" color="primary" [disabled]="disableSaveButtonIfNothingSelected && !isOneSelected"
        (click)="closeWithSelection()" (keyup)="closeWithSelection()" i18n>
        Übernehmen
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>