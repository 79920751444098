<span id="scannerWrapperSpan" #scannerWrapper>
  <ng-container *ngIf="onlyUseNative; else elseNative">
    <h1 *ngIf="nativeScanState === 1" class="infoText" i18n>Bereit zum Scannen...</h1>
    <h1 *ngIf="nativeScanState === 2 && scannerData" class="infoText" i18n>Daten wurden erfolgreich gescannt!</h1>
    <input type="text" [ngModel]="scannerData" (ngModelChange)="changedScannerData($event)" (blur)="initNative()"
      #scannerNativeInput>
    <span class="buttonsWrapper">
      <ion-button color="danger" expand="block" (click)="closeModal()" i18n>
        Abbrechen
      </ion-button>
    </span>
  </ng-container>
  <ng-template #elseNative>
    <h1 *ngIf="accessDenied" class="errorText" i18n>Der Zugriff auf die Kamera wurde verweigert.</h1>
    <span class="buttonsWrapperTop">
      <ion-button expand="block" (click)="toggleTorch()" *ngIf="torchSupported$ | async">
        <ion-icon name="flashlight-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </span>
    <div id="reader"></div>
    <span class="buttonsWrapperBottom">
      <ion-button color="danger" expand="block" (click)="closeModal()" i18n>
        Abbrechen
      </ion-button>
    </span>
  </ng-template>
</span>