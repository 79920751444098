import {NewOrderHelper} from '../../../../providers/component-helpers/new-order-helper.service';
import {Injectable} from '@angular/core';
import {UserHelper} from '../../../../providers/helpers/user-helper.service';
import {UserModel} from '../../../../models/users/user';
import {EmergencyOrderFormModel} from '../models/emergency-order-form.model';
import {OrderModel} from '../../../../models/order';
import {ChecklistsHelper} from '../../../../providers/helpers/checklists-helper.service';
import {IWriteableChecklist} from '../../../../../packages/checklistManagement/models/checklist';
import {MachineModel} from '../../../../models/machine';
import {AssemblyReportModel} from '../../../../models/assembly_report';

/**
 * Service zum Erstellen des Notfallauftrags
 */
@Injectable()
export class EmergencyOrderSubmitService {
    constructor(
        // HELPER SERVICES
        private readonly userHelper: UserHelper,
        private readonly newOrderHelper: NewOrderHelper,
        private readonly checklistsHelper: ChecklistsHelper,
    ) {
    }

    /**
     * Speichert den Notfallauftrag
     * @param emergencyOrderFormInput Die Eingaben des Notfallauftrags aus dem Formular
     */
    public async submitEmergencyOrder(
        emergencyOrderFormInput: EmergencyOrderFormModel,
    ): Promise<void> {
        try {
            // Falls kein Kunde ausgewählt wurde, wird der Auftrag nicht gespeichert
            if(!emergencyOrderFormInput?.customer?.id) return;
            // User holen
            const user: UserModel = this.userHelper.getUser();
            // Falls kein User vorhanden ist, wird der Auftrag nicht gespeichert
            if(!user?.id) return;
            // Order erstellen
            const order: OrderModel = this.newOrderHelper.newOrder(user, null, emergencyOrderFormInput.customer, true);
            // Falls kein Auftrag erzeugt werden konnte, wird der Auftrag nicht gespeichert
            if(!order) return;
            // Beschreibung des Auftrags aus Formular setzen
            order.description = emergencyOrderFormInput.description;
            // Auftragsstandort setzen
            order.customer_location_id = emergencyOrderFormInput.location?.id;
            // Ansprechpartner-ID des Kunden setzen, falls im Formular gesetzt
            if(emergencyOrderFormInput.customer_contact_id) {
                order.customer_contact_id = emergencyOrderFormInput.customer_contact_id;
            }
            // Checklisten in WriteableChecklists umwandeln
            order.writeable_checklists = await this.checklistsHelper.mapAndDeleteIds(
                emergencyOrderFormInput.checklists as IWriteableChecklist[],
                'Order',
                order.id
            );
            // Assembly Reports auf Basis der Maschinen setzen
            order.assembly_reports = emergencyOrderFormInput.machines.map((machine: MachineModel) => {
                return {
                    machine,
                    machine_id: machine.id,
                } as AssemblyReportModel;
            });
            // Speichern des Notfallauftrags
            await this.newOrderHelper.saveEmergencyOrder(order, false);
        } catch (error) {
            console.error('[EmergencyOrderSubmitService][submitEmergencyOrder()]: Fehler beim Erstellen des Notfallauftrags', error);
        }
    }
}