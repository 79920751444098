import { Directive, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MitsFormObjectSelectionComponent } from 'src/app/components/_form-components/mits-form-object-selection/mits-form-object-selection.component';
import {Identifiable} from '../../../../models/basic';

/**
 * Direktive, zur Nutzung der MitsFormObjectSelectionComponent in Angular Reactive Forms
 */
@Directive({
  standalone: true,
  selector: 'mits-form-object-selection',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MitsFormObjectSelectionControlValueAccessorDirective),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MitsFormObjectSelectionControlValueAccessorDirective),
      multi: true
    }
  ]
})
export class MitsFormObjectSelectionControlValueAccessorDirective<T extends Identifiable> implements ControlValueAccessor, Validator {

  constructor(private host: MitsFormObjectSelectionComponent<T>) {
    host.changedSelection.subscribe((value: T | T[]) => {
      let output: T | T[] | number | number[] | null;
      if (this.host.returnObjectsInsteadOfIds) {
        output = value;
      } else {
        if (this.host.multiple) {
          output = (value as T[]).map(item => item.id);
        } else {
          output = (value as T)?.id;
        }
      }
      this.onChange(output);
    });
  }

  // Register the onChange function to emit the ID
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Register the onTouched function
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Set the disabled state of the host component
  setDisabledState(isDisabled: boolean): void {
    this.host.disabled = isDisabled;
  }

  writeValue(value: number | number[] | T | T[] | null): void {
    if (this.host.returnObjectsInsteadOfIds) {
      this.host.value = value as any;
    } else {
      this.host.setValueById(value as number | number[]);
    }
  }

  // Validate the control (no validation logic added yet)
  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  private onChange = (id:  number | number[] | T | T[] | null) => {
  }; // Changed to emit the id instead of the value

  private onTouched = () => {};
}
