<div id="languageButton" (click)="isPopoverOpen = true">
  <span id="languageIcon" class="fi fi-{{ selectedLanguageService.currentLanguage?.flag }}"></span>
</div>

<ion-popover trigger="languageButton" side="top" alignment="start" [isOpen]="isPopoverOpen" (didDismiss)="isPopoverOpen = false">
  <ng-template>
    <ion-list>
      <ion-item button *ngFor="let lang of selectedLanguageService.supportedLanguages" (click)="selectLanguage(lang.languageCode)">
        <span class="fi fi-{{ lang.flag }}"></span>{{ lang.language }}
      </ion-item>
    </ion-list>
  </ng-template>
</ion-popover>
