import {
  MITS_DATA_GRID_BREAKPOINT_MAPPINGS,
  MitsDataGridColumnConfig,
  MitsDataGridBreakpoints
} from 'src/app/components/mits-data-grid/model';
import {Identifiable} from '../../../models/basic';

/**
 * Erzeugt den Wert 'grid-template-columns'
 * @returns der Wert für 'grid-template-columns
 * @private
 */
export function buildGridTemplateColumns<T extends Identifiable>(columnConfigs: MitsDataGridColumnConfig<T>[]): string {
  // Breakpoint für die aktuelle Fenstergröße ermitteln
  const currentBreakpoint: MitsDataGridBreakpoints = getCurrentBreakpoint();
  // Sichtbare Spalten ermitteln auf Basis der Spaltenkonfiguration und des übergebenen Thresholds
  const visibleColumns: MitsDataGridColumnConfig<T>[] = columnConfigs.filter((column: MitsDataGridColumnConfig<T>) => {
    return !(column.breakpointConfig?.[currentBreakpoint]?.hide);
  });

  const columnWidths: string[] = visibleColumns.map((col: MitsDataGridColumnConfig<T>) => {
    const width = parseFloat(getColumnWidth(col, currentBreakpoint) as string);
    return !isNaN(width) ? `${width}fr` : 'auto';
  });
  return columnWidths.join(' ');
}

/**
 * Gibt den aktuellen Breakpoint zurück auf Basis der window.innerWidth
 * @returns den Breakpoint
 */
export function getCurrentBreakpoint(): MitsDataGridBreakpoints {
  for (const [size, width] of Object.entries(MITS_DATA_GRID_BREAKPOINT_MAPPINGS)) {
    if (window.innerWidth < width) {
      return size as MitsDataGridBreakpoints;
    }
  }
  return 'xxl' // Standardmäßig den größten Breakpoint
}

/**
 * Ermittelt die Breite auf Basis der Thresholdkonfiguration der Spalte
 * @param column - Die Spalte, für die die Breite ermittelt werden soll
 * @param currentBreakpoint - Der currentBreakpoint-Wert des Fensters
 * @returns Die Breite der Spalte aus der Konfiguration oder 'auto'
 */
function getColumnWidth<T extends Identifiable>(column: MitsDataGridColumnConfig<T>, currentBreakpoint: MitsDataGridBreakpoints): string {
  const columnThresholdConfig = column.breakpointConfig?.[currentBreakpoint];
  return columnThresholdConfig?.width || column.width?.toString() || 'auto';
}


