import { TimelogState } from '../models';

export const stateConfig: { [index: string]: string } = {
  [TimelogState.Unkown]: $localize`Unbekannt`,
  [TimelogState.Previous]: $localize`Vorherig`,
  [TimelogState.DayStarting]: $localize`Tagesstart`,
  [TimelogState.Working]: $localize`Arbeitszeit`,
  [TimelogState.DayEnding]: $localize`Tagesende`,
  [TimelogState.Individual]: $localize`Individuell`,
  [TimelogState.CarEquiping]: $localize`Fahrzeug rüsten`,
  [TimelogState.CarDriving]: $localize`Fahrzeit`,
  [TimelogState.CustomerWorking]: $localize`Kundenzeit`,
  [TimelogState.MachineWorking]: $localize`Automatenzeit`,
  [TimelogState.BufferstockEquiping]: $localize`Bufferstock rüsten`,
};
