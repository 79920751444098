import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { InfiniteScrollCustomEvent, ModalController } from '@ionic/angular';
import { BasicModel } from 'src/app/models/basic';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { ScannerData } from '../../mits-scanner-button/mits-scanner-button.component';
import { MitsObjectSelectHelper } from '../helpers/mits-object-select-helper';
import {
  DisplayNameConfig,
  DisplayObject,
  SearchFilterFunc,
} from './../mits-object-select.component';
import { FormControl } from '@angular/forms';
import { debounceTime, firstValueFrom } from 'rxjs';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';

@Component({
  selector: 'mits-object-select-modal',
  templateUrl: './mits-object-select-modal.page.html',
  styleUrls: ['./mits-object-select-modal.page.scss'],
})
export class MitsObjectSelectModalPage<T extends BasicModel> {
  /** Show a custom header over the Searchbar inside the object-select */
  @Input() headerTemplate: TemplateRef<any>;
  /** All objects but mapped to include more information */
  @Input() displayObjects: DisplayObject<T>[];
  /** Array of object-ids wich should be disabled */
  @Input() disabledObjectIds: number[] = [];
  /** The name of the attributes to display (primary and secondary) the objects e.g. [['name1', 'name2'],['address.zip','address.city']] */
  @Input() displayNameVariables: DisplayNameConfig;
  /** The title shown in the item label */
  @Input() objectTypeTitle = $localize`Objekt`;
  /** The title shown in the modal header after the objectTypeTitle */
  @Input() modalTitleAttachment = $localize`auswählen`;
  /** The text shown in the cancel button */
  @Input() modalBackButtonText = $localize`Abbrechen`;
  /** The title shown in the modal header after the objectTypeTitle */
  @Input() enableMultiSelect;
  /** If an object gets selected, should the modal automaticly close? */
  @Input() enableCloseAfterSelect;
  /** Should the search get automaticly focused after the modal gets opened? */
  @Input() enableAutofocusSearch;
  /** If only one object is found, it automaticly gets selected? */
  @Input() enableInstantSelect;
  /** If no object is selected should the save-button get disabled? */
  @Input() disableSaveButtonIfNothingSelected;
  /** Disables the possibility to deselect an element (disables all selected elements) */
  @Input() disableSelectedElements = false;
  /** Saves the last executed search to load next time opening modal */
  @Input() saveLastSearch = false;
  /** If provided the search inside the modal will have this text as a placeholder */
  @Input() searchPlaceholderText = '';
  /** Selects the search-text if it adds instantly the defined length or more of chars */
  @Input() selectOnPasteLength: number | null = null;
  /** Should the clear-input-button of the searchbar be shown? */
  @Input() showClearInputButton: boolean = true;
  /** Should each information in the subtitles be shown in separate lines? */
  @Input() seperateLinesForSubtitle: boolean = true;
  /** Handle the search for uself */
  @Input() searchFilter: SearchFilterFunc<T>;
  @Input() infinityLoadingService?: OfflineDataService<T>;
  @Input() infinityLoadingArgs?: any;
  /** Volltext noch nicht vearbeitet, zeigt Fehlermeldung in UI an */
  @Input() fulltextRunning: boolean = false;
  /** Should the user be able to click on a disabled element? */
  @Input() enableClickDisabledElement = false;
  /** Selected Objects displayed on top of the modal */
  @Input() selectedObjects: DisplayObject<T>[] = [];
  /** FLAG: String wich gets searched  */
  searchControl = new FormControl<string | null>(null);
  /** FLAG: IS at least one element selected? */
  isOneSelected: boolean;
  /** FLAG: Search before last search */
  searchBeforeLast: string;
  /** Current page for infinity loading */
  page = 0;
  /** Der indexeddb index, zu dem der cursor für die nächsten 25 Einträge springen soll */
  searchIndex: number = 0;
  /** Sind keine zusätzlichen Daten mehr zu Laden ? */
  infiniteFinished: boolean = false;
  /** Um scan mit infinity zu handlen */
  scanResult: boolean = false

  get searchText(): string {
    return this.searchControl.value;
  }

  set searchText(v: string) {
    this.searchControl.setValue(v);
  }

  @ViewChild('mitsObjSelSearchInput')
  mitsObjSelSearchInput: ElementRef<HTMLInputElement>;

  constructor(
    public readonly mitsObjectSelectHelper: MitsObjectSelectHelper<T>,
    private readonly modalCtrl: ModalController
  ) {}

  /**
   * Bevor die View geladen wird, muss schon der letzte Text gesetzt werden
   */
  ionViewWillEnter() {
    if (this.saveLastSearch && this.mitsObjectSelectHelper.lastSearch) {
      this.searchText = this.mitsObjectSelectHelper.lastSearch;
      this.searchBeforeLast = this.searchText;
    }
    this.searchControl.valueChanges
      .pipe(debounceTime(400))
      .subscribe((value) => {
        this.handleSearchChange(value);
      });
  }

  /**
   * Seite wurde vollständig geladen
   */
  async ionViewDidEnter() {
    if (this.enableAutofocusSearch) {
      this.mitsObjSelSearchInput.nativeElement.focus();
      if (this.saveLastSearch) {
        this.mitsObjSelSearchInput.nativeElement.select();
      }
    }
    this.infinityLoadingService
      ? this.loadInfinityData()
      : await this.doSearch(this.searchText ?? '');

      this.selectedObjects = this.displayObjects.filter((so) => so.selected)
    this.updateIsOneSelected();
  }

  /**
   * Searches for the scanned data
   * @param ev scanned data
   */
  scannedData(ev: ScannerData) {
    this.scanResult = true
    this.searchText = ev.decodedResult.decodedText;
  }

  /**
   * Gets called if a object gets selected
   * @param index of selected object
   */
  selected(index: number, value: any) {
    const obj = this.displayObjects[index];
    if (
      this.enableClickDisabledElement &&
      this.disableSelectedElements &&
      obj.selected
    ) {
      this.closeWithSelection(this.displayObjects[index]);
      return;
    }
    if (!this.enableMultiSelect) {
      this.displayObjects = this.displayObjects.map((o) => {
        o.selected = false;
        return o;
      });
    }
    this.displayObjects[index].selected = value;
    if (this.enableCloseAfterSelect) {
      this.closeWithSelection();
      return;
    }
    obj.selected
      ? this.selectedObjects.push(obj)
      : (this.selectedObjects = this.selectedObjects.filter(
          (so) => so.object.id !== obj.object.id
        ));
    this.updateIsOneSelected();
  }

  async onIonInfinite(ev: Event) {
    if (this.infinityLoadingService && !this.infiniteFinished) {
      await this.loadInfinityData();
    }
    (ev as InfiniteScrollCustomEvent).target.complete();
  }

  /**
   * Closes the modal
   */
  close() {
    this.modalCtrl.dismiss();
  }

  /**
   * Passes the selected objects while closing the modal
   */
  async closeWithSelection(disabledElementClick?: DisplayObject<T>) {
    const overlay = await this.modalCtrl.getTop();
    if (!overlay) return;
    if (this.enableClickDisabledElement && disabledElementClick) {
      this.modalCtrl.dismiss({
        displayObjects: this.displayObjects,
        disabledElementClick: disabledElementClick,
      });
      return;
    }
    this.modalCtrl.dismiss({ displayObjects: this.displayObjects });
  }

  /**
   * Selects the object if only one is shown
   */
  private selectIfOne() {
    const notHidden = this.displayObjects.filter((diob) => !diob.hidden);
    if (
      notHidden.length === 1 &&
      (!notHidden[0].selected || this.enableClickDisabledElement)
    ) {
      if (this.enableClickDisabledElement && notHidden[0].selected) {
        this.closeWithSelection(notHidden[0]);
        return;
      }
      notHidden[0].selected = true;
      if (this.enableCloseAfterSelect) this.closeWithSelection();
      this.updateIsOneSelected();
    }
  }

  /**
   * Executes the search
   */
  private async doSearch(search: string) {
    const objs = await this.searchFilter(
      this.mitsObjectSelectHelper.getDisplayObjectsAsObjects(
        this.displayObjects
      ),
      search
    );

    if (search && search.length < this.searchText.length) {
      return;
    } else {
      let markText = false;
      this.mitsObjSelSearchInput.nativeElement.addEventListener(
        'paste',
        (event) => {
          const clipboardData = event.clipboardData || window['clipboardData'];
          const pastedNumber = clipboardData.getData('Text');
          if (pastedNumber.length >= this.selectOnPasteLength) {
            markText = true;
          }
        }
      );
      if (markText) {
        this.mitsObjSelSearchInput.nativeElement.select();
        markText = false;
      }
    }
    // Bisherige Objekte ein und ausblenden
    this.displayObjects = this.displayObjects.map((diOb) => {
      diOb.hidden = objs.findIndex((o) => o.id === diOb.object.id) < 0;
      return diOb;
    });
    // TODO: Komponente kann optimiert werden. @tomuench mit Olli besprechen.
    // Neue Objekte zum einfügen
    const newDisplayObjects =
      this.mitsObjectSelectHelper.applyObjectsToDisplayObjects(
        objs.filter((obj) => {
          return (
            this.displayObjects.findIndex((diOb) => obj.id === diOb.object.id) <
            0
          );
        }),
        this.displayNameVariables
      );

    this.displayObjects = this.displayObjects.concat(newDisplayObjects);

    if (this.enableInstantSelect && this.searchBeforeLast !== search) {
      this.selectIfOne();
    }
  }

  private async handleSearchChange(val: string) {
    if (this.infinityLoadingService) {
      if (!val || val.length >= 3) {
        this.page = 0;
        this.searchIndex = 0;
        this.infiniteFinished = false;
        this.displayObjects = GlobalHelper.deepClone(this.selectedObjects);
        this.loadInfinityData();
      }
    } else {
      this.doSearch(val);
    }
  }

  private async loadInfinityData() {
    this.page++;
    const res = await firstValueFrom(
      this.infinityLoadingService.localWhereByObjectName(
        this.searchIndex,
        this.searchText ?? ''
      )
    );
    const newObjs: T[] = res.data;
    this.searchIndex = res.index;
    this.infiniteFinished = res.last;
    const newDispObjs: DisplayObject<T>[] =
      this.mitsObjectSelectHelper.applyObjectsToDisplayObjects(
        newObjs,
        this.displayNameVariables
      );
    if(this.scanResult && newDispObjs.length === 1) {
        newDispObjs[0].selected = true
        this.closeWithSelection(newDispObjs[0])
      }
    for (let ndo of newDispObjs) {
      if (this.selectedObjects.some((so) => so.object.id === ndo.object.id))
        continue;
      this.displayObjects.push(ndo);
    }
    this.scanResult = false
  }

  /**
   * deletes input on button-click
   */
  handleButtonClick() {
    this.mitsObjSelSearchInput.nativeElement.value = '';
    this.mitsObjSelSearchInput.nativeElement.focus();
  }

  /**
   * Updates variable if at least one element is selected
   */
  updateIsOneSelected() {
    this.isOneSelected = this.displayObjects.some((d) => d.selected);
  }

  /**
   * Überprüft anhand der ID, ob das Objekt durch die Konfiguration der Komponente (disabledObjectIds) deaktiviert ist
   * @param id Die ID des Objekts
   * @returns true, wenn das Objekt deaktiviert ist
   */
  isDisabledObject(id: number | undefined): boolean {
    return this.disabledObjectIds.some((disabledId) => disabledId === id);
  }
}
