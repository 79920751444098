import {EmergencyOrderPage} from './emergency-order-page.component';

// Die Parent-Route für den Notfallauftrag
export const EMERGENCY_ORDER_BASE_ROUTE = 'emergency-order-new';

// Die Child-Routen für den Notfallauftrag
export const EMERGENCY_ORDER_CHILD_ROUTES = [
  {
    path: '',
    component: EmergencyOrderPage,
  },
];