import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * Direktive, die automatisch die CSS-Klassen 'b_valid' und 'b_invalid' hinzufügt,
 * wenn sich der Status eines Form-Controls ändert.
 */
@Directive({
  selector: '[formControlStatus]',
  standalone: true,
})
export class FormControlStatusDirective implements OnInit, OnDestroy {
  @Input('formControlStatus') controlName: string;
  private subscription: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private formGroupDirective: FormGroupDirective
  ) {
  }

  /**
   * Initialisiert die Direktive
   */
  ngOnInit() {
    // FormControl aus FormGroup holen
    const control: FormControl<any> = this.formGroupDirective.control.get(this.controlName) as FormControl;

    if (!control) {
      console.warn(`[FormControlStatusDirective] Control '${this.controlName}' nicht in FormGroup gefunden.`);
      return;
    }

    // Status-Änderungen abonnieren und CSS-Klassen neu setzen
    this.subscription = control.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.renderer.addClass(this.el.nativeElement, 'b_valid');
        this.renderer.removeClass(this.el.nativeElement, 'b_invalid');
      } else if (status === 'INVALID') {
        this.renderer.addClass(this.el.nativeElement, 'b_invalid');
        this.renderer.removeClass(this.el.nativeElement, 'b_valid');
      }
    });

    // CSS-Klassen initial setzen
    if (control.valid) {
      this.renderer.addClass(this.el.nativeElement, 'b_valid');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'b_invalid');
    }
  }

  /**
   * Wird aufgerufen, wenn die Direktive zerstört wird
   */
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
