<ng-container *ngIf="gridTemplateColumns">

  <div class="table" [ngClass]="currentBreakpoint === mobileLayoutBreakpoint? 'is-mobile' : ''"
       [ngStyle]="{'grid-template-columns': gridTemplateColumns}">
    <div class="table-header">
      <ng-container *ngFor="let column of columnConfigs">
        <div class="table-cell" *ngIf="!(!!column.breakpointConfig?.[currentBreakpoint]?.hide)" (click)="!column.notOrderable && emitOrderBy(column)">
          <strong>{{ column.label ?? column.attribute }}</strong>
          <span *ngIf="!column.notOrderable && (orderedByIndicator?.attribute === column.attribute) || (orderedByIndicator?.attribute === column.orderByParam)">
            {{ orderedByIndicator.direction === 'ASC' ? '▲' : '▼' }}
          </span>
        </div>
      </ng-container>
    </div>

    <div class="table-row" *ngFor="let row of data" (click)="itemsClickable ? itemClicked.emit(row.id) : ''"
         [ngClass]="{'clickable': itemsClickable}">
      <ng-container *ngFor="let column of columnConfigs">
        <div class="table-cell" *ngIf="!(!!column.breakpointConfig?.[currentBreakpoint]?.hide)">
          <ng-container *ngIf="currentBreakpoint !== mobileLayoutBreakpoint; else mobileLayout">
              <div *ngIf="column.HTMLElementFactory" [mitsHtmlRenderer]="column.HTMLElementFactory" [value]="row[column.attribute]"></div>
            <ion-badge *ngIf="column.specialType === 'badge'" [color]="getColumnColor(row, column)">
              {{ column.transformValue ? column.transformValue(row[column.attribute]) : row[column.attribute] }}
            </ion-badge>
            <span *ngIf="!column.specialType && !column.HTMLElementFactory"
                  [innerHTML]="column.transformValue ? column.transformValue(row[column.attribute]) : row[column.attribute]"></span>
          </ng-container>

          <ng-template #mobileLayout>
            <div class="mobile-cell">
              <span class="label">{{ column.label ?? column.attribute }}:</span>
              <ion-badge *ngIf="column.specialType === 'badge'">
                {{ column.transformValue ? column.transformValue(row[column.attribute]) : row[column.attribute] }}
              </ion-badge>
              <span *ngIf="!column.specialType" class="value"
                    [innerHTML]="column.transformValue ? column.transformValue(row[column.attribute]) : row[column.attribute]"></span>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>

  </div>
</ng-container>
